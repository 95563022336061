import { useTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'
import cs from 'classnames'

// ///////////////////////////////////////////////////////////////////// SELECTORS & ACTIONS
import * as apiState from '../../../modules/api/selectors'
import * as authState from '../../../modules/auth/selectors'
import * as entityState from '../../../modules/entities/selectors'
import connectTo from '../../../modules/entities/actions/connect'

// ///////////////////////////////////////////////////////////////////// COMPONENTS
import Image from '../../Img/image'
import FaceList from '../../smart-face-list'
import * as stats from '../entry-stats'
import ActionButton from './ActionButton'
import { AuthorizationResourceLink } from '../../../ui/AuthorizationResourceLink'

interface GroupInviteAdminProps {
  card: any
  accept: (resource) => void
  ignore: (resource) => void
  connected?: boolean
  ignored?: boolean
  accepting?: boolean
  ignoring?: boolean
}

const GroupInviteAdmin = (props: GroupInviteAdminProps) => {
  const { t } = useTranslation('updates')
  const { t: tGroup } = useTranslation('group')
  const { card, ignoring, ignored, connected, accepting } = props
  const resource = card.resource
  const target = resource.target
  const actor = card.actor
  const titleLines =
    target.title.length < 35 ? 1 : target.title.length < 75 ? 2 : 3
  const actionMessages = {
    connected: 'updates.group_invite_admin_accepted',
    accepted: 'updates.group_invite_admin_accepting',
    accept: 'updates.group_invite_admin_accept',
  }
  const containerClass = cs('listing__item activity-panel', {
    'activity-panel--ignored': props.ignored || props.ignoring,
  })

  return (
    <div className={containerClass}>
      <div className="activity-panel__header dark-background">
        <h3 className="activity-panel__title">
          {t('updates.group_invite_admin_title', { user_name: actor.name })}
        </h3>
        <ActionButton messages={actionMessages} {...props} withTick t={t} />
      </div>
      <div className="activity-panel__body">
        <div className="activity-panel__body-inner">
          <p>
            {t('updates.group_invite_admin_intro', {
              user_name: actor.name,
              group_name: target.title,
            })}
          </p>
          <div className="media">
            <AuthorizationResourceLink className="media__img" to={target}>
              <Image
                url={target.image_url || actor.avatar_url}
                size="group-thumb"
              />
            </AuthorizationResourceLink>
            <div
              className={`media__body media__body--title-lines-${titleLines}`}
            >
              <AuthorizationResourceLink
                className="activity__title"
                to={target}
              >
                {target.title}
              </AuthorizationResourceLink>
              <div className="activity__description">
                {target.description.split('\n\n')[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="activity-panel__footer">
        <div className="card__stats">
          <FaceList users={resource.users} count={4} />
          <span className="card__stats__numbers">
            {stats.getStatsForGroup(resource, tGroup) || ' '}
          </span>
        </div>
        {!ignoring && !ignored && !connected && !accepting && (
          <button
            className="action-link"
            onClick={() => props.ignore(resource)}
          >
            {t('updates.group_invite_admin_ignore')}
          </button>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state, props) {
  const resource = props.card.resource
  const user = authState.selectAuthenticatedUser(state)
  return {
    connected: resource.is_admin,
    ignored: entityState.userHasIgnoredGroupInvite(
      state,
      user,
      resource.target
    ),
    accepting: apiState.isRequestActive(
      state,
      connectTo(resource.target.type, resource.target.id, true)
    ),
    ignoring: apiState.isRequestActive(
      state,
      connectTo(resource.target.type, resource.target.id, false)
    ),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accept: resource =>
      dispatch(connectTo(resource.target.type, resource.target.id, true)),
    ignore: resource =>
      dispatch(connectTo(resource.target.type, resource.target.id, false)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupInviteAdmin)
