import React from 'react'
import { useSelector } from '../../../react-redux'
import { selectAuthenticatedUser } from '../../../modules/auth/selectors'
import { selectSpecialtyMapper } from '../../../modules/categories/selectors'
import {
  userHasConnection,
  userHasRequestedConnection,
} from '../../../modules/entities/selectors'
import NewMember from './NewMember'

export const MemberNewDentist = props => <NewMember {...props} kind="dentist" />
export const MemberNewNurse = props => <NewMember {...props} kind="nurse" />
export const MemberNewStudent = props => <NewMember {...props} kind="student" />

export const MemberNewInInstitution = props => (
  <NewMember {...props} InInstitution />
)

export const FollowingCase = props => <NewMember {...props} FollowingCase />
export const MemberFollowingCase = FollowingCase

export const MemberNewInSubSpecialty = props => {
  const currentUser = useSelector(selectAuthenticatedUser)
  const specialties = useSelector(selectSpecialtyMapper)

  const common_subspecialty_count =
    currentUser && specialties
      ? specialties.getCommonSubspecialties(
          currentUser.specialty,
          props.card.actor.specialty
        ).length
      : 0
  return (
    <NewMember
      {...props}
      common_subspecialty_count={common_subspecialty_count}
    />
  )
}

///////////////////////////////////////////////////// COMMENTOR pass "Commentor"
export const MemberNewCommentor = props => {
  const connectionSent = useSelector(
    state =>
      userHasConnection(state, props.card.actor.id) ||
      userHasRequestedConnection(state, props.card.actor.id)
  )
  return <NewMember {...props} connectionSent={connectionSent} Commentor />
}
