import get from 'lodash/get'
import { chain, fromPairs, map, zip } from 'ramda'
import { connect } from 'react-redux'

import { BasicUser } from '../../../types/userTypes'
import { selectUser } from '../../entities/selectors'

const mapStateToProps = (userIDPaths?: Array<string>) => (state, ownProps) => {
  if (userIDPaths) {
    const ids = chain(path => get(ownProps, path), userIDPaths)
    return {
      userMap: fromPairs(
        zip(
          ids,
          map(id => selectUser(state, id), ids)
        )
      ),
    }
  }
  return {
    getUserForID: id => selectUser(state, id),
  }
}

export type UserLookupProps = {
  getUserForID?: (id: string) => BasicUser
  userMap?: { [id: string]: BasicUser }
}

export const withUserLookup = (component: any, paths?: Array<string>) => {
  return connect(mapStateToProps(paths))(component)
}
