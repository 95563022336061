import React from 'react'
import AwardBadge from '../icons/award-badge'

import Image, { Sizes } from '../Img/image'
import ProfileBadges from './profile-badges'
import { AuthorizationResourceLink } from '../AuthorizationResourceLink'
import { rawTextTrimmer } from '../formatters/text'

export type Props = {
  user: any
  className?: string
  size?: Sizes
  children?: React.ReactNode
  show_institution?: boolean
  badges?: boolean
  advert?: boolean
  isHighlighted?: boolean
  style?: Record<string, string>
  modalId?: string
  isLearning?: boolean
}

const ProfileBasic = ({
  className,
  size = 'profile-small',
  user,
  children,
  advert = false,
  show_institution,
  isHighlighted,
  style,
  modalId,
  isLearning,
}: Props): React.ReactElement => {
  const containerClasses = `${className} fs-unmask profile-basic media media--${size.replace(
    'profile-',
    ''
  )}`
  if (!user) return null

  const hideIconsFilter = ![
    'card__profile',
    'activity-panel__profile',
    'activity-panel__connect',
  ].some(c => className?.includes(c) || advert)

  return (
    <div className={containerClasses} style={style}>
      <AuthorizationResourceLink
        to={user}
        className="media__img profile-basic__img"
        resource_type="user"
        modalId={modalId}
        isLearning={isLearning}
      >
        <Image
          url={user.avatar_url || `/images/profile/${user.id}/{image_size}`}
          size={size || 'profile-large'}
          width={
            size === 'profile-small' ? 30 : size === 'profile-large' ? 85 : 60
          }
          shape={'circle'}
          inlineStyle={
            user.award === 'gold' && hideIconsFilter
              ? {
                  border: `4px solid transparent`,
                  backgroundImage:
                    'radial-gradient(circle, rgba(255,252,168,1) 0%, rgba(253,184,54,1) 35%, rgba(253,201,102,1) 63%, rgba(241,220,131,1) 100%)',
                  backgroundClip: 'border-box',
                  borderRadius: '50%',
                }
              : {
                  border: isHighlighted ? 'solid' : null,
                  borderColor: isHighlighted ? '#0088ff' : null,
                }
          }
        />
      </AuthorizationResourceLink>

      <div className="media__body">
        <AuthorizationResourceLink
          to={user}
          resource_type="user"
          className="profile-basic__name media__title"
          modalId={modalId}
          isLearning={isLearning}
        >
          <span
            className="profile-basic__name-text"
            style={{ fontWeight: isHighlighted ? 900 : null }}
          >
            {advert
              ? rawTextTrimmer({ text: user.name, maxLetters: 35 })
              : user.name}
            {user.site_role &&
            !['activity-panel__profile', 'activity-panel__connect'].some(c =>
              className?.includes(c)
            ) ? (
              <div className="profile-badges">
                <ProfileBadges user={user} />
              </div>
            ) : null}
          </span>
        </AuthorizationResourceLink>

        {!advert && (
          <div className="profile-basic__info">
            <div className="profile-basic__job-title">{user.job_title}</div>
            {size === 'profile-large' || show_institution ? (
              <div className="profile-basic__institution media__info">
                {user.main_institution_name}
              </div>
            ) : null}
            {hideIconsFilter && user.site_role === 'Clinical Leader' && (
              <div className="profile-badges">
                {user.award && <AwardBadge award={user.award} width={22} />}
                <span>MedShr Clinical Leader</span>
              </div>
            )}
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileBasic
