import { useTranslation } from 'next-i18next'
import React from 'react'

import SmartFollowButton from '../../buttons/smart-follow'
import MediaBox from '../MediaBox'
import { AuthorizationResourceLink } from '../../../ui/AuthorizationResourceLink'
import style from './styles/flex.module.scss'

type Props = {
  card: any
  isMobile?: boolean
}

const CaseCommented = ({ card }: Props): any => {
  const { t } = useTranslation('updates')
  const { resource, actor } = card
  const target = resource.target
  const commentCount = card.comment_count
  const sideKickBtn = (
    <SmartFollowButton flex className="btn--iconic" resource={target} />
  )

  return target && actor ? (
    <MediaBox target={target} customeClass="activity--case activity--spacious">
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.headTitle}>
            <AuthorizationResourceLink className="activity__title" to={target}>
              {target.title}
            </AuthorizationResourceLink>
          </div>
          <div>{sideKickBtn}</div>
        </div>

        <div className="activity__reason media__info">
          {t(
            commentCount > 1
              ? 'updates.case_commented_multiple'
              : 'updates.case_commented',
            commentCount > 1
              ? { comment_count: commentCount }
              : { user_name: actor.name }
          )}
        </div>
      </div>
    </MediaBox>
  ) : null
}

export default CaseCommented
