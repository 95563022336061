import cs from 'classnames'
import React from 'react'

import { withUserLookup } from '../../modules/entities/components/user-lookup'
import { AuthorizationResourceLink } from '../AuthorizationResourceLink'
import SmartConnectGroupButton from '../buttons/smart-connect'
import FaceList from '../face-list'
import { getSizeStringWithPixelDensity } from '../../ui/Img/image'

type Props = {
  card: any
  getUserForID: Function
  resourceContext?: any
  isLearning: boolean
}

const UserCard = (props: Props): any => {
  const { card, resourceContext } = props
  const target = card.target
  const isLearning = props.isLearning
  const styles = {
    header: {
      backgroundImage: target.avatar_url
        ? `url(${card.target.avatar_url.replace(
            '{image_size}',
            getSizeStringWithPixelDensity('feed-image')
          )})`
        : 'none',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundColor: target.theme?.header_background_color ?? 'transparent',
    },
  }
  const colleagueMemberCount = card.colleague_member_count || 0
  const otherMemberCount = card.other_member_count || 0
  const totalCount = colleagueMemberCount + otherMemberCount

  return (
    <div className={cs('card', `card--${target.type}`, 'fs-unmask')}>
      <div className="card__resource">
        <AuthorizationResourceLink
          className="card__text-link"
          to={target}
          resourceContext={resourceContext}
          isLearning={isLearning}
        >
          <h2 className={cs('card__title', {})} style={styles.header}>
            <span className="card__text--link-text">
              <span className="fs-unmask">{target.name}</span>
            </span>
          </h2>
        </AuthorizationResourceLink>
      </div>
      <div className="card__footer">
        <div className="card__stats">{target.job_title}</div>
        <div className="card__followers">
          {renderCardFollower(props, totalCount, isLearning)}
        </div>
        <div className="card__action">
          <SmartConnectGroupButton user={target} wide />
        </div>
      </div>
    </div>
  )
}

export const renderCardFollower = (
  { card, getUserForID },
  totalCount,
  isLearning
) => {
  if (card.users) {
    return (
      <FaceList
        users={Array.isArray(card.users) ? card.users.map(getUserForID) : []}
        count={4}
        total={totalCount}
        isLearning={isLearning}
      />
    )
  }

  return null
}

export default withUserLookup(UserCard)
