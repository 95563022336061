import { useTranslation } from 'next-i18next'
import React from 'react'
import map from 'lodash/map'
import cs from 'classnames'

import { withUserLookup } from '../../../modules/entities/components/user-lookup'
import TextFormatter from '../../formatters/text'
import Image from '../../Img/image'
import FaceList from '../../face-list'
import SmartJoinButton from '../../buttons/smart-join-group'
import * as stats from '../entry-stats'
import { AuthorizationResourceLink } from '../../../ui/AuthorizationResourceLink'

interface Props {
  card: any
  getUserForID: (id: string | number) => void
  className?: string
}

const GroupManageRow = ({ card, getUserForID, className }: Props) => {
  const { t } = useTranslation('group')
  const classes = cs(
    className,
    `card-manage card-row card-row--${card.target.type}`
  )

  return (
    <div className={classes}>
      <div className="media">
        {(() => {
          if (card.target.image_url) {
            return (
              <AuthorizationResourceLink
                className="media__img"
                to={card.target}
              >
                <Image
                  url={card.target.image_url}
                  size="item"
                  className="card-row__image"
                />
              </AuthorizationResourceLink>
            )
          }
        })()}
        <div className="media__body">
          <h2 className="card-row__title media__title">
            <AuthorizationResourceLink
              className="card__text-link"
              to={card.target}
            >
              {card.target.title}
            </AuthorizationResourceLink>
          </h2>
          <TextFormatter
            className="card-manage__description card-row__description media__subtitle"
            text={card.target.description || ''}
            maxSentences={1}
          />
          <p className="card-row__stats media__info">
            {stats.getStatsForGroup(card, t)}
            {card.new_activity_count && (
              <span className="card-row__stats-updates">
                |
                <span className="card-row__stats-update-count">
                  {t('"group.new_activity_count', {
                    count: card.new_activity_count,
                  })}
                </span>
              </span>
            )}
          </p>
        </div>
        <div className="card-manage__footer">
          {card.is_admin ? undefined : (
            <SmartJoinButton
              resource={card}
              group={card.target}
              showLeave={true}
              className="btn--iconic card-manage__action"
              dark
            />
          )}
          <FaceList
            users={map(card.users, id => getUserForID(id))}
            count={4}
            total={card.colleague_member_count + card.other_member_count}
          />
        </div>
      </div>
    </div>
  )
}

export default withUserLookup(GroupManageRow)
