import React from 'react'

import {
  LearningModuleRevisionType,
  LearningModuleWithSessionType,
  LearningSessionScore,
  LearningSeriesType,
  McqSettingsPassMarkType,
} from '../../../../graphql/learning'
import ModuleRowCard from './ModuleRowCard'
import ModuleRowTable from './ModuleRowTable'
import { CurrentUserModuleStatus, ViewType } from './types'
import { generateLinkAndButtonLabel, getCommonRowData } from './utils'

type ModulesList = {
  position: number
  ever_completed: boolean
  has_passed: boolean
  published_revision?: {
    mcq_settings_pass_mark_type?: McqSettingsPassMarkType
  }
  revision?: {
    mcq_settings_pass_mark_type?: McqSettingsPassMarkType
  }
}

interface Props {
  module: LearningModuleWithSessionType
  series: LearningSeriesType
  revision: LearningModuleRevisionType
  session?: LearningSessionScore
  moduleStatus: string
  currentUserModuleStatus?: CurrentUserModuleStatus
  viewType: ViewType
  ix: number
  showGotoDashboardButton: boolean
  returnToSeries?: boolean
  isFeed?: boolean
  modulesList: ModulesList[]
}

const ModuleRow = ({
  revision,
  currentUserModuleStatus,
  viewType,
  ix,
  showGotoDashboardButton,
  returnToSeries,
  session,
  module,
  series,
  isFeed = false,
  modulesList,
}: Props): JSX.Element => {
  const currentModulePosition = module.position

  const previousModulesCompleted = modulesList
    ?.filter(m => m.position < currentModulePosition)
    .every(m => {
      const passMarkType =
        m?.published_revision?.mcq_settings_pass_mark_type ??
        m?.revision?.mcq_settings_pass_mark_type

      return passMarkType === McqSettingsPassMarkType.none
        ? m.ever_completed
        : m.has_passed
    })

  const { buttons } = generateLinkAndButtonLabel({
    series,
    module,
    revision,
    everPublished: !!module.published_revision?.id,
    currentUserModuleStatus,
    previousModulesCompleted,
  })
  const isRated = module.ratings?.length > 0
  const isReflected = module.reflections?.length > 0

  const commonRowData = getCommonRowData(revision, buttons)

  if (viewType === ViewType.cards) {
    return (
      <ModuleRowCard
        module={module}
        commonRowData={commonRowData}
        lastCompletedSession={session?.active ? undefined : session}
        isRated={isRated}
        isReflected={isReflected}
        showGotoDashboardButton={showGotoDashboardButton}
        returnToSeries={returnToSeries}
        series={series}
        isFeed={isFeed}
      />
    )
  } else if (viewType === ViewType.table) {
    return (
      <ModuleRowTable
        revision={revision}
        commonRowData={commonRowData}
        ix={ix}
        currentUserModuleStatus={currentUserModuleStatus}
        module={module}
        series={series}
      />
    )
  }
}

export default ModuleRow
