import cs from 'classnames'
import React from 'react'

import SmartProfileBasicWithConnect from '../../smart-profile-basic-with-connect'

const UserRow = ({ card, className }) => {
  const classes = cs(className, `card-row card-row--${card.target.type}`)

  return (
    <div className={classes}>
      <SmartProfileBasicWithConnect user={card.target} />
    </div>
  )
}

export default UserRow
