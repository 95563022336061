import React from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// /////////////////////////////////////////////////////////////////////// UTILS
import noop from '../../utils/noop'
// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  width?: number
  id?: string
  title?: string
  className?: string
  onClick?: Function
}

const defaultProps = {
  width: 29,
  id: 'icon-poll',
  title: 'Poll',
}

const PollIcon = ({
  width,
  title,
  id,
  className,
  onClick,
}: Props = defaultProps): React.ReactElement => {
  width = width ?? defaultProps.width
  title = title ?? defaultProps.title
  id = id ?? defaultProps.id
  return (
    <Icon
      width={width}
      sourceWidth={29}
      sourceHeight={24}
      title={title}
      id={id}
      className={className}
      onClick={onClick}
    >
      <defs>
        <filter
          x="-16.0%"
          y="-16.7%"
          width="132.0%"
          height="133.3%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="0"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="1"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.6 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          filter="url(#filter-1)"
          transform="translate(2.000000, 0.000000)"
          fill="#FFFFFF"
        >
          <polygon id="Rectangle-5" points="0 9 25 9 25 15 0 15"></polygon>
          <rect id="Rectangle-5" x="0" y="1" width="19" height="6"></rect>
          <rect id="Rectangle-5" x="0" y="17" width="15" height="6"></rect>
        </g>
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default PollIcon
