import React from 'react' // eslint-disable-line
import cs from 'classnames'

interface ContainerProps {
  className?: string
  children?: React.ReactNode
}

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

export const Container = ({ className, children }: ContainerProps) => (
  <div className={cs('container', className)}>{children}</div>
)

interface LayoutProps {
  className?: string
  children?: React.ReactNode
  flush?: boolean
}

export const Layout = ({ flush, className, children }: LayoutProps) => (
  <div className={cs('layout', { 'layout--flush': flush }, className)}>
    {children}
  </div>
)

export const getSizeClassFromProps = (props, deviceClass?: string) => {
  const prefix = deviceClass ? `${deviceClass}_` : ''
  const suffix = deviceClass ? `-${deviceClass.replace(/_/g, '-')}` : ''
  const cols = props[`${prefix}cols`]
  const span = props[`${prefix}span`] || 1

  if (cols > 0) {
    return `u-${span}-of-${cols}${suffix}`
  }

  return undefined
}

export const getSizeClassesFromProps = props => {
  let classNames = []
  let sizeClass

  if ((sizeClass = getSizeClassFromProps(props))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromProps(props, 'palm'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromProps(props, 'lap'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromProps(props, 'portable'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromProps(props, 'desk'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromProps(props, 'large_desk'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromProps(props, 'desk_and_up'))) {
    classNames.push(sizeClass)
  }

  return classNames.join(' ')
}

export const getSizeClassFromLayoutObject = (props, deviceClass?: string) => {
  const layout = props[deviceClass ? deviceClass : '_']
  if (layout) {
    const suffix = deviceClass ? `-${deviceClass.replace(/_/g, '-')}` : ''

    if (typeof layout === 'object') {
      const cols = layout['cols']
      const span = layout['span'] || 1
      if (cols > 0) {
        return `u-${span}-of-${cols}${suffix}`
      }
    } else {
      return `u-1-of-${layout}${suffix}`
    }
  }
  return undefined
}

export const getSizeClassesFromLayoutObject = props => {
  let classNames = []
  let sizeClass

  if ((sizeClass = getSizeClassFromLayoutObject(props))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromLayoutObject(props, 'palm'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromLayoutObject(props, 'palm_landscape'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromLayoutObject(props, 'palm_portrait'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromLayoutObject(props, 'lap'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromLayoutObject(props, 'portable'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromLayoutObject(props, 'desk'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromLayoutObject(props, 'large_desk'))) {
    classNames.push(sizeClass)
  }
  if ((sizeClass = getSizeClassFromLayoutObject(props, 'desk_and_up'))) {
    classNames.push(sizeClass)
  }

  return classNames.join(' ')
}

export const Item = (props: any) => {
  const { layout, className, children } = props
  const sizeClasses = layout
    ? getSizeClassesFromLayoutObject(layout)
    : getSizeClassesFromProps(props)

  return (
    <div
      className={cs('layout__item', className, sizeClasses)}
      style={props.style}
    >
      {children}
    </div>
  )
}
