import React from 'react'
import ModuleRow from '../../components/Learning/learning-modules/rows/ModuleRow'
import {
  CurrentUserModuleStatus,
  ViewType,
} from '../../components/Learning/learning-modules/rows/types'
import { useSelector } from '../../react-redux'

interface Props {
  card: any
  getUserForID: Function
  resourceContext?: any
}

const Card = (props: Props): any => {
  const learningEnabled = useSelector(store => !!store?.features?.learning)
  if (!learningEnabled) return null

  const item = props.card.target

  const revision = {
    module: {
      ...item,
      revision: {
        ...item.revision,
        series: item.series,
      },
    },
    revision: {
      ...item.revision,
      series: item.series,
    },
  }

  const session = revision.module.latestSession
    ? revision.module.latestSession[0]
    : undefined

  const currentUserModuleStatus = session?.active
    ? CurrentUserModuleStatus.inProgress
    : session?.events &&
      session.events[session.events.length - 1].event === 'finish'
    ? CurrentUserModuleStatus.completed
    : CurrentUserModuleStatus.null

  return (
    <ModuleRow
      key={revision.revision.module_id}
      session={session}
      moduleStatus={revision.module.status}
      module={revision.module}
      revision={revision.revision}
      series={item.series}
      currentUserModuleStatus={currentUserModuleStatus}
      viewType={ViewType.cards}
      showGotoDashboardButton={false}
      ix={null}
      isFeed={true}
      modulesList={item.series.modules}
    />
  )
}

export default Card
