import { useTranslation } from 'next-i18next'
import React from 'react'
import cs from 'classnames'

import ProfileImageLink from './Profile/profile-image-link'

type Props = {
  users: Array<any>
  count: number
  total?: number
  children?: React.ReactNode
  className?: string
  user_map?: any
  plus_type?: string
  isLearning?: boolean
}

const FaceList = (props: Props): React.ReactElement => {
  const { t } = useTranslation('common')

  if (!props.users) {
    return null
  }

  const users = props.count ? props.users.slice(0, props.count) : props.users
  const total = typeof props.total === 'number' ? props.total - users.length : 0

  return (
    <ul className={cs('profile-list', 'list-inline', props.className)}>
      {renderList(users, props)}
      {total > 0 ? (
        <li className="profile-list__plus-more">
          {t(
            props.plus_type === 'common_connections'
              ? 'plus_common_connections'
              : 'plus_more',
            { count: total }
          )}
        </li>
      ) : null}
      {props.children}
    </ul>
  )
}

function renderList(users: Array<Object>, props: any): any {
  return users.map((user: any): any => {
    const isUser: any =
      typeof user === 'object'
        ? user
        : props.user_map
        ? props.user_map[user]
        : undefined

    if (isUser && isUser.avatar_url) {
      return (
        <li key={isUser.id} className="profile-list__profile">
          <ProfileImageLink
            width={30}
            shape={'circle'}
            user={isUser}
            size="profile-small"
            isLearning={props.isLearning}
          />
        </li>
      )
    }
  })
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default FaceList
