import { useTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import * as apiState from '../../../modules/api/selectors'
import * as entityState from '../../../modules/entities/selectors'
import {
  accept,
  ignore,
} from '../../../modules/entities/actions/group_admin_join_request'
import Spinner from '../../icons/spinner'
import ProfileBasic from '../../Profile/profile-basic'

interface Props {
  card: any
  accept: (resource, actor) => JSX.Element
  ignore: (resource, actor) => JSX.Element
  connected?: boolean
  ignored?: boolean
  accepting?: boolean
  ignoring?: boolean
}

const GroupAdminJoinRequest = ({
  card,
  connected,
  accepting,
  accept,
  ignored,
  ignore,
  ignoring,
}: Props) => {
  const { t } = useTranslation('updates')
  const resource = card.resource
  const target = resource.target
  const actor = card.actor

  return (
    <div className={classNames('listing__item activity-panel')}>
      <div className="activity-panel__header dark-background">
        <h3 className="activity-panel__title">
          {t('updates.institution_admin_join_request_title', {
            user_name: actor.name,
            group_name: target.title,
          })}
        </h3>
        {connected ? (
          <button className="btn btn--fill btn--dark btn--wide">
            {t('updates.institution_admin_join_request_accepted')}
          </button>
        ) : accepting ? (
          <button className="btn btn--disabled btn--wide">
            {t('updates.institution_admin_join_request_accepting')}
            <Spinner width={15} />
          </button>
        ) : ignored || ignoring ? (
          <button className="btn btn--disabled btn--wide">
            {t('updates.institution_admin_join_request_accept')}
          </button>
        ) : (
          <button
            className="btn btn--wide"
            onClick={() => accept(resource, actor)}
          >
            {t('updates.institution_admin_join_request_accept')}
          </button>
        )}
      </div>
      <div className="activity-panel__body">
        <div className="activity-panel__body-inner">
          <ProfileBasic
            user={actor}
            size="profile-thumb"
            className="activity-panel__profile"
            show_institution
          />
        </div>
      </div>
      <div className="activity-panel__footer">
        <div className="card__reason">
          {t('updates.institution_admin_join_request_reason', {
            user_name: actor.name,
            user_first_name: actor.name.replace(/^([^ ]+).*$/, '$1'),
            group_name: resource.title,
          })}
        </div>
        {ignored || ignoring ? (
          <span className="action-link">
            {t('updates.institution_admin_join_request_ignored')}
          </span>
        ) : !ignoring && !ignored && !connected && !accepting ? (
          <button
            className="action-link"
            onClick={() => ignore(resource, actor)}
          >
            {t('updates.institution_admin_join_request_ignore')}
          </button>
        ) : null}
      </div>
    </div>
  )
}

function mapStateToProps(state, props) {
  const resource = props.card.resource
  const actor = props.card.actor
  return {
    connected:
      entityState.userHasGroupMembership(state, actor, resource.target) ||
      entityState.hasAcceptedJoinRequest(state, resource.target, actor.id),
    ignored:
      entityState.userHasIgnoredGroupInvite(state, actor, resource.target) ||
      entityState.hasIgnoredJoinRequest(state, resource.target, actor.id),
    accepting: apiState.isRequestActive(
      state,
      accept(resource.target.type, resource.target.id, actor.id)
    ),
    ignoring: apiState.isRequestActive(
      state,
      ignore(resource.target.type, resource.target.id, actor.id)
    ),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accept: (resource, actor) =>
      dispatch(accept(resource.target.type, resource.target.id, actor.id)),
    ignore: (resource, actor) =>
      dispatch(ignore(resource.target.type, resource.target.id, actor.id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAdminJoinRequest)
