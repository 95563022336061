import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import React, { createElement, useEffect } from 'react'

import { postNotification } from '../modules/notify/actions'
import { redirect, redirectAs } from '../modules/routing/actions'
import { connect } from '../react-redux'

type Props = {
  check: Function
  children: React.ReactNode
  redirect: Function
}

type State = {
  renderable: boolean
  redirecting: boolean
  error?: any
}

class RedirectComponent extends React.Component<Props, State> {
  constructor(props, context) {
    super(props, context)

    this.state = { renderable: false, redirecting: false }
  }

  componentDidMount() {
    this.props.check(this.props, this.redirect, this.ok, this.error)
  }

  componentDidUpdate(prevProps: Props) {
    if (
      isEqual(prevProps, this.props) ||
      this.state.renderable ||
      this.state.redirecting
    ) {
      return
    }

    this.props.check(this.props, this.redirect, this.ok, this.error)
  }

  redirect = (url, optional_notification) => {
    this.setState({ redirecting: true })
    this.props.redirect(url, optional_notification)
  }

  ok = () => {
    this.setState({ renderable: true })
  }

  error = e => {
    this.setState({ renderable: false, error: e })
  }

  render(): React.ReactElement {
    const { renderable, error } = this.state

    if (renderable) return <>{this.props.children}</>
    else if (error) return <div className="error">{error}</div>

    return null
  }
}

// ///////////////////////////////////////////???????////////////////// REDIRECT
export const Redirect = connect(null, dispatch => ({
  redirect(url, optional_notification) {
    if (typeof url === 'string') {
      dispatch(redirect(url))
    } else {
      dispatch(redirectAs(url.to, url.as))
    }
    optional_notification && dispatch(postNotification(optional_notification))
  },
}))(RedirectComponent)

// /////////////////////////////////////////////////////////////// WITH REDIRECT
export const withRedirect = (
  check?: (
    nextProps: any,
    redirect: (
      url: string | { to: string; as?: string },
      notification?: any
    ) => void,
    ok: () => boolean,
    error: (e: any) => void
  ) => void
) => {
  return (component?: any) => {
    const RedirectingComponent = props => {
      const router = useRouter()
      return createElement(
        Redirect,
        { ...props, check, router },
        component && createElement(component, props)
      )
    }
    return RedirectingComponent
  }
}

const Redirecter = ({ url }: { url: string }) => {
  useEffect(() => {
    document.location.href = url
  }, [])
  return (
    <div className="page__content">
      <p>Please wait while we redirect you…</p>
    </div>
  )
}

export const makeRedirectComponent = (url: string) => {
  const R = () => <Redirecter url={url} />
  return R
}
