import cs from 'classnames'
import React from 'react'

import {
  LearningModuleRevisionType,
  LearningSeriesType,
  LearningModuleWithSessionType,
} from '../../../../graphql/learning'
import { SubscribeButton } from '../../../Subscriptions/cart/SubscribeButton'
import { SubscriptionType } from '../../../Subscriptions/types'
import { CurrentUserModuleStatus } from './types'

interface Props {
  commonRowData: any
  revision: LearningModuleRevisionType
  ix: number
  currentUserModuleStatus: CurrentUserModuleStatus
  module: LearningModuleWithSessionType
  series: LearningSeriesType
}

const ModuleRowTable = ({
  commonRowData,
  revision,
  ix,
  currentUserModuleStatus,
  module,
  series,
}: Props): JSX.Element => {
  const {
    needs_subscription: needsSubscription,
    has_subscription: hasSubscription,
  } = module
  const {
    payments_enabled: subscriptionBased,
    subscription_settings: subscriptionSettings,
  } = series

  const isSubscription =
    subscriptionBased && subscriptionSettings?.available_as_module

  return (
    <tr
      className={cs('fs-unmask learning-tbl__tr', {
        'learning-tbl__tr--even': ix % 2 === 0,
      })}
    >
      <td className="learning-tbl__td--hide-mobile">
        <img
          src={commonRowData.module.coverImage}
          width={40}
          height={40}
          style={{ objectFit: 'cover' }}
          alt={'Module Cover image'}
        />
      </td>
      <td>
        <div style={{ fontSize: 13, color: '#000' }}>{series.title}</div>
        <div style={{ fontWeight: 500 }}>{revision.title}</div>
        {(function (currentUserModuleStatus) {
          switch (currentUserModuleStatus) {
            case CurrentUserModuleStatus.inProgress:
              return (
                <div style={{ color: 'orange', fontSize: 12, fontWeight: 600 }}>
                  In Progress
                </div>
              )
            case CurrentUserModuleStatus.completed:
              return (
                <div style={{ color: 'green', fontSize: 12, fontWeight: 600 }}>
                  Completed
                </div>
              )
            case CurrentUserModuleStatus.null:
              return null
            default:
              return <div>Error occurred</div>
          }
        })(currentUserModuleStatus)}
      </td>
      <td
        className="learning-tbl__td--hide-mobile"
        style={{ whiteSpace: 'nowrap' }}
      >
        <div>{commonRowData.module.authorInfo()}</div>
        {/* <div>On <b>2nd Aug</b></div> */}
      </td>
      <td>
        <div style={{ display: 'flex', maxWidth: 180 }}>
          {isSubscription && needsSubscription && !hasSubscription ? (
            <SubscribeButton
              productID={module.id}
              type={SubscriptionType.MODULE}
              availableAsSeries={subscriptionSettings?.available_as_series}
              seriesID={module.series_id}
              libraryID={series.library_id}
              label={
                subscriptionSettings.subscription_interval
                  ? 'Subscribe'
                  : 'Buy now'
              }
            />
          ) : (
            commonRowData.buttons
          )}
        </div>
      </td>
    </tr>
  )
}

export default ModuleRowTable
