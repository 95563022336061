import { useTranslation } from 'next-i18next'
import React from 'react'

import { Image } from '../../Img'
import { FeedCard, Target } from '../updates/flowTypes'

const imageSize = 'sidebar-resource-image'

const ImageLink = ({ target }: { target: Target }) => {
  const { t } = useTranslation('updates')
  return (
    <Image
      adaptive
      url={target.image_url}
      size={imageSize}
      alt={t('updates.sidebar_resource_title', { title: target.title })}
    />
  )
}

const SidebarResourceImage = ({ card }: { card: FeedCard }) => {
  const target = card.resource.target

  return (
    <div className="listing__item activity-panel activity-panel--sidebar-resource-image">
      {target.url ? (
        <a href={target.url} target="_blank" rel="noreferrer noopener">
          <ImageLink target={target} />
        </a>
      ) : (
        <ImageLink target={target} />
      )}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default SidebarResourceImage
