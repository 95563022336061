import { useTranslation } from 'next-i18next'
import React from 'react'

import SmartJoinGroupButton from '../../buttons/smart-join-group'
import MediaBox from '../MediaBox'
import { AuthorizationResourceLink as AuthSourceLink } from '../../../ui/AuthorizationResourceLink'

import style from './styles/flex.module.scss'

import { FeedCard } from './flowTypes'

type Props = {
  card: FeedCard
}

const ConnectionJoinedGroupContainer = ({
  card,
  joinable,
  children,
}: Props & { joinable?: boolean; children: React.ReactNode }) => {
  const { resource, verb } = card
  const target = resource.target
  const sideKickBtn = joinable && (
    <SmartJoinGroupButton flex resource={resource} group={target} />
  )

  return (
    <MediaBox target={target} verb={verb} customeClass="activity--member" group>
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.headTitle}>
            <AuthSourceLink
              to={target}
              textContent={target.title}
              className="activity__title"
            />
          </div>
          <div>{sideKickBtn}</div>
        </div>

        {children}
      </div>
    </MediaBox>
  )
}

export const ConnectionJoinedGroup = (props: Props) => {
  const { t } = useTranslation('updates')
  const { actor } = props.card
  return (
    <ConnectionJoinedGroupContainer {...props} joinable>
      <div className="activity__reason-prefix">
        <AuthSourceLink to={actor} textContent={actor.name} />{' '}
        {t('updates.other_joined_group')}
      </div>
      <div className="activity__reason" />
    </ConnectionJoinedGroupContainer>
  )
}

export const ConnectionJoinedThisGroup = (props: Props) => {
  const { t } = useTranslation('updates')
  const { actor } = props.card
  return (
    <ConnectionJoinedGroupContainer {...props}>
      <div className="profile-basic__job-title">{actor.job_title}</div>
      <div className="activity__reason">
        {t('updates.connection_joined_this_group')}
      </div>
    </ConnectionJoinedGroupContainer>
  )
}
