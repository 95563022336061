import { useTranslation } from 'next-i18next'
import React from 'react'

import CreateCase from '../../buttons/CreateCase'
import MediaBox from '../MediaBox'

const Welcome = (): any => {
  const { t } = useTranslation()
  const sideKickBtn = <CreateCase className="btn--iconic" />

  return (
    <MediaBox
      src="/static/media/images/notification@3x.png"
      alt="MedsShr logo"
      sideKick={sideKickBtn}
    >
      <div className="u-8-of-12">{t('updates:updates.welcome')}</div>
    </MediaBox>
  )
}

export default Welcome
