import React from 'react'
import { UserType } from '../../types/userTypes'

import MedshrEditorBadge from '../icons/medshr-editor-badge'
import ClinicalLeaderBadge from '../icons/clinical-leader-badge'
import StudentAmbassadorBadge from '../icons/student-ambassador-badge'

interface Props {
  user: UserType
  size?: number
}

const ProfileBadges = ({ user, size = 22 }: Props) => {
  switch (user.site_role) {
    case 'Clinical Leader':
      return <ClinicalLeaderBadge width={size} />
    case 'Student Ambassador':
      return <StudentAmbassadorBadge width={size} />
    case 'MedShr Team':
      return <MedshrEditorBadge width={size} />
    default:
      return null
  }
}

export default ProfileBadges
