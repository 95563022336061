import { request } from '../../api/actions'
import * as schemas from '../../entities/schemas'
import * as types from '../../entities/types'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

export const LOAD_REQUEST = 'entities/member_load.REQUEST'
export const LOAD_SUCCESS = 'entities/member_load.SUCCESS'
export const LOAD_FAILURE = 'entities/member_load.FAILURE'

// /////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////// EXPORT DEFAULT
export default function loadMember(id) {
  const query =
    '?connections_per_page=1000&include=cases%2Cmemberships&cases_per_page=6'

  return request({
    url: `/api/profile/feeds/user/${encodeURIComponent(id)}${query}`,
    start: { type: LOAD_REQUEST, resource_id: id },
    success: {
      type: LOAD_SUCCESS,
      meta: {
        schema: {
          response: schemas.getUserEntrySchema(),
          cases: schemas.getFeedSchema(types.FEED_USER_CASES, id),
        },
      },
    },
    failure: LOAD_FAILURE,
  })
}
