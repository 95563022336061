import React from 'react'
import MediaBox from '../MediaBox'
import { getSizeStringWithPixelDensity } from '../../../ui/Img/image'

const PinnedMessage = ({ card }): any => {
  const target = card.resource.target

  if (!target.body) {
    return null
  }

  if (target.image_url) {
    return (
      <MediaBox
        customeClass="activity--pinned-message"
        src={target.image_url.replace(
          '{image_size}',
          getSizeStringWithPixelDensity('profile-thumb')
        )}
        alt=""
        square
      >
        <div className="u-8-of-12">{target.body}</div>
      </MediaBox>
    )
  }

  return (
    <div className="listing__item activity activity--pinned-message">
      <div className="u-8-of-12">{target.body}</div>
    </div>
  )
}

export default PinnedMessage
