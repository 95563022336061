import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import {
  selectAuthenticatedUser,
  selectIsAuthenticating,
} from '../modules/auth/selectors'
import { isAdmin } from '../modules/entities/user'
import { useSelector } from '../react-redux'
import { AuthenticatedUser } from '../types/userTypes'
import { Modal, ModalBody } from '../ui/modals'
import { redirectAuthCheck } from '../url'
import { makeRedirectComponent } from './with-redirect'

const WithUserConnected = ({
  childComponent: ChildComponent,
  fallbackComponent: FallbackComponent = null,
  requireAuth,
  ...props
}) => {
  const currentUser = useSelector(selectAuthenticatedUser)
  const isAuthenticating = useSelector(selectIsAuthenticating)
  return isAuthenticating ? null : (
    <WithUserConnectedX
      key={currentUser?.id}
      currentUser={currentUser}
      childComponent={ChildComponent}
      fallbackComponent={FallbackComponent}
      requireAuth={requireAuth}
      {...props}
    />
  )
}

const WithUserConnectedX = ({
  currentUser,
  childComponent: ChildComponent,
  fallbackComponent: FallbackComponent = null,
  requireAuth,
  ...props
}) => {
  const router = useRouter()

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT &&
      requireAuth &&
      !/authed=/.test(document.cookie) &&
      !FallbackComponent
    ) {
      redirectAuthCheck()
    }
  }, [requireAuth])

  return currentUser ? (
    <ChildComponent
      key={'child'}
      router={router}
      currentUser={currentUser}
      requireAuth={requireAuth}
      {...props}
    />
  ) : FallbackComponent ? (
    <FallbackComponent
      key={'fallback'}
      router={router}
      currentUser={currentUser}
      requireAuth={requireAuth}
      {...props}
    />
  ) : null
}

// /////////////////////////////////////////////////////////////////// WITH USER
export const withUser = (childComponent, fallbackComponent = undefined) => {
  const WithEntity = props => (
    <WithUserConnected
      {...props}
      childComponent={childComponent}
      fallbackComponent={fallbackComponent}
    />
  )
  return WithEntity
}

// //////////////////////////////////////////////////////////////// REQUIRE USER
export const requireUser = (childComponent, fallbackComponent = undefined) => {
  const WithEntity = props => {
    return (
      <WithUserConnected
        {...props}
        requireAuth={true}
        childComponent={childComponent}
        fallbackComponent={fallbackComponent}
      />
    )
  }

  return WithEntity
}

export const requireUserWhere = (
  check: (user: AuthenticatedUser) => boolean,
  childComponent,
  fallbackComponent
) => {
  const WithEntity = withUser(props => {
    if (check(props.currentUser)) {
      return React.createElement(childComponent, props)
    }
    return React.createElement(fallbackComponent, props)
  })

  return WithEntity
}

export const requireVerifiedUser = (
  childComponent,
  fallbackComponent = undefined
) => {
  return requireUserWhere(
    u => !!u.verified,
    childComponent,
    fallbackComponent ??
      makeRedirectComponent('/api/profile/verification-redirect')
  )
}

export const requireUnverifiedUser = (
  childComponent,
  fallbackComponent = undefined
) => {
  return requireUserWhere(
    u => !u.verified,
    childComponent,
    fallbackComponent ??
      makeRedirectComponent('/api/profile/verification-redirect')
  )
}

// /////////////////////////////////////////////////////////////// REQUIRE ADMIN
export const requireAdmin = childComponent => {
  const RequireUser = requireUser(props => {
    if (isAdmin(props.currentUser)) {
      return React.createElement(childComponent, props)
    }
    return (
      <Modal id="noaccess">
        <ModalBody className="modal--dialog modal--centered">
          <span>Sorry, you do not have access to this page</span>
        </ModalBody>
      </Modal>
    )
  })

  return RequireUser
}
