import React from 'react'

import ProfileBasic, { Props } from './profile-basic'
import SmartConnectButton from '../buttons/smart-connect'

export type PropsWithConnect = Props & {
  connected?: boolean
  modalId?: string
}

const ProfileBasicWithConnect = (props: PropsWithConnect) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    <ProfileBasic
      {...props}
      badges
      className="activity-panel__connect"
      style={{ flexGrow: '1' }}
    />
    {!props.connected ? (
      <SmartConnectButton
        className="btn--iconic-small profile-basic__connect"
        user={props.user}
      />
    ) : null}
  </div>
)

export default ProfileBasicWithConnect
