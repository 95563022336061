import { useTranslation } from 'next-i18next'
import Link from '../../../ui/link'
import React from 'react'
import cs from 'classnames'

import { withUserLookup } from '../../../modules/entities/components/user-lookup'
import Image from '../../Img/image'
import { AuthorizationResourceLink } from '../../AuthorizationResourceLink'
import * as stats from '../entry-stats'
import SmartFollowButton from '../../buttons/smart-follow'
import MedShrLogo from '../../medshr-logo'

type Props = {
  card: any
  getUserForID: Function
  className?: string
  showCreator?: boolean
}

const placeHolderLogo = () => (
  <div className="image-placeholder--thumb">
    <MedShrLogo width={40} />
  </div>
)

const CaseRow = ({
  className,
  card,
  getUserForID,
  showCreator,
}: Props): JSX.Element => {
  const cardRowClass: string = `card-row card-row--${card.target.type}`
  const classes: string = cs(cardRowClass, {
    className: className,
  })
  const creator: any = showCreator ? getUserForID(card.target.created_by) : null

  const { t } = useTranslation('case')

  return (
    <div className={classes}>
      <div className="media">
        <Link href={card.target.url} className="media__img">
          {card.target.image_url ? (
            <Image
              url={card.target.image_url}
              size="item"
              className="card-row__image fs-exclude"
            />
          ) : (
            placeHolderLogo()
          )}
        </Link>

        <div className="media__body">
          <Link
            className="card-row__title media__title card__text-link"
            href={card.target.url}
          >
            {card.target.title}
          </Link>
          {creator && (
            <p className="card-row__creator media__info">
              {creator.name}, {creator.job_title}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default withUserLookup(CaseRow)
