import { useTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'
import cs from 'classnames'
import map from 'lodash/map'
import filter from 'lodash/filter'
import get from 'lodash/get'

import * as apiState from '../../../modules/api/selectors'
import * as authState from '../../../modules/auth/selectors'
import hideUpdate from '../../../modules/entities/actions/hideUpdate'
import { link_for_resource } from '../../../url'
import { withUserLookup } from '../../../modules/entities/components/user-lookup'
import { TextLink } from '../../link'
import Image from '../../Img/image'
import ResourceLink from '../../resource-link'
import TextFormatter from '../../formatters/text'
import XIcon from '../../icons/x'

type Props = {
  cardListComponent: any
  card: any
  currentUser: any
  accept: Function
  ignore: Function
  getUserForID: Function
  ignored?: boolean
  ignoring?: boolean
  isMobile?: boolean
}

const LIMIT = 2

const ConnectionInvite = (props: Props): any => {
  const { t } = useTranslation()
  const { getUserForID, currentUser, card, ignoring, ignored } = props
  const resource = card.resource
  const target = resource.target
  const members = resource.other_members
    ? filter(resource.other_members, g => g != currentUser.id)
    : []
  const CardListComponent = props.cardListComponent

  const containerClasses = cs(
    'listing__item activity-panel activity-panel--group',
    { 'activity-panel--ignored': props.ignored || props.ignoring }
  )
  const titleClasses = cs('activity-panel__title', {
    'activity-panel__title--fullwidth': false,
  })

  return (
    <div className={containerClasses}>
      <div className="activity-panel__header dark-background">
        <h3 className={titleClasses}>
          {t('updates:updates.group_invite_accepted_title', {
            name: target.name,
          })}
        </h3>
        {!ignoring && !ignored && (
          <button className="action-link" onClick={() => props.ignore(card)}>
            <XIcon width={16} />
          </button>
        )}
      </div>
      <div className="activity-panel__body">
        <div className="activity-panel__body-inner">
          <div className="activity-panel__profile">
            <div className="media">
              {resource.target.image_url && (
                <ResourceLink to={resource.target} className="media__img">
                  <Image
                    url={resource.target.image_url}
                    size="item"
                    className="card-row__image"
                  />
                </ResourceLink>
              )}

              <div className="media__body">
                <ResourceLink
                  className="card-row__title media__title resource__text-link"
                  to={resource.target}
                >
                  {resource.target.title}
                </ResourceLink>
                <TextFormatter
                  className="card-row__description media__subtitle"
                  text={resource.target.description || ''}
                  maxSentences={1}
                  maxWords={20}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="activity-panel__body-section">
          <div className="card__links">{renderCardLinks(resource, target)}</div>
        </div>
        {renderCases(props)}

        {members.length > 1 ? (
          <CardListComponent
            cards={map(members, g => {
              return { target: getUserForID(g) }
            }).filter((i, index) => !!i.target && index < LIMIT)}
            layout={{ _: 1 }}
            cardStyle="row"
          />
        ) : null}
      </div>
      {members.length <
      (resource.other_member_count || 0) +
        (resource.colleague_member_count || 0) ? (
        <div className="activity-panel__footer">
          <TextLink
            className="action-link"
            to={link_for_resource(
              resource.target.type,
              resource.target.id,
              'members'
            )}
          >
            {t('group:group.view_all_members')}
          </TextLink>
        </div>
      ) : null}
    </div>
  )
}

function renderCardLinks(resource: any, target: any) {
  const { t } = useTranslation()
  const { other_member_count, colleague_member_count, cases_count_label } =
    resource
  const links = []

  if (cases_count_label) {
    links.push(
      <TextLink
        key="cases"
        className="action-link"
        to={link_for_resource(target.type, target.id, 'cases')}
      >
        {cases_count_label}
      </TextLink>
    )
    links.push(<span key="cases-sep"> | </span>)
  }
  if ((other_member_count || 0) + (colleague_member_count || 0)) {
    links.push(
      <TextLink
        key="members"
        className="action-link"
        to={link_for_resource(target.type, target.id, 'members')}
      >
        {t('group:group.member_count', {
          count: (other_member_count || 0) + (colleague_member_count || 0),
        })}
      </TextLink>
    )
    links.push(<span key="members-sep"> | </span>)
  }

  links.pop()

  return links
}

function renderCases({ card, cardListComponent }: Props) {
  const resource = card.resource
  const CardListComponent = cardListComponent
  const { t } = useTranslation('group')

  if (get(resource, 'cases.length', 0) > 1) {
    return (
      <React.Fragment>
        <CardListComponent
          cards={resource.cases.filter((_i, index) => index < LIMIT)}
          layout={{ _: 1 }}
          cardStyle="row"
          cardProps={{ showStats: true, showFollowButton: true }}
        />
        <div className="activity-panel__footer">
          <TextLink
            className="action-link"
            to={link_for_resource(
              resource.target.type,
              resource.target.id,
              'cases'
            )}
          >
            {t('group.view_all_cases', { count: resource.cases.length })}
          </TextLink>
        </div>
      </React.Fragment>
    )
  }
  return null
}

const mapStateToProps = (state: any, { card }: Props): any => {
  const target = get(card, 'resource.target', {})
  const user = authState.selectAuthenticatedUser(state)

  return {
    currentUser: user,
    ignored: card.visible === false,
    ignoring: apiState.isRequestActive(state, hideUpdate(card)),
  }
}
const mapDispatchToProps = (dispatch: Function): any => ({
  ignore: card => dispatch(hideUpdate(card)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUserLookup(ConnectionInvite))
