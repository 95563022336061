import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

// //////////////////////////////////////////////////////////////////// SELECTOR
import { withUserLookup } from '../../modules/entities/components/user-lookup'
import { AuthorizationResourceLink } from '../AuthorizationResourceLink'
import SmartJoinGroupButton from '../buttons/smart-join-group'
import { getSizeStringWithPixelDensity } from '../../ui/Img/image'

import * as stats from './entry-stats'
import { renderCardFollower } from './user'

type Props = {
  card: any
  getUserForID: Function
  resourceContext?: any
  isLearning: boolean
}

const GroupCard = (props: Props): any => {
  const { t } = useTranslation('group')

  const { card, resourceContext } = props
  const target = card.target
  const isLearning = props.isLearning

  const styles = {
    header: {
      backgroundImage: target.image_url
        ? `url(${card.target.image_url.replace(
            '{image_size}',
            getSizeStringWithPixelDensity('feed-image')
          )})`
        : 'none',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundColor: target.theme?.header_background_color ?? 'transparent',
    },
  }

  const colleagueMemberCount = card.colleague_member_count ?? 0
  const otherMemberCount = card.other_member_count ?? 0
  const totalCount = colleagueMemberCount + otherMemberCount

  return (
    <div className={cs('card', `card--${target.type}`, 'fs-unmask')}>
      <div className="card__resource">
        <AuthorizationResourceLink
          className="card__text-link"
          to={target}
          resourceContext={resourceContext}
          isLearning={isLearning}
        >
          <h2
            className={cs('card__title', {
              'card__title--hidden': !target.theme.display_card_title,
            })}
            style={styles.header}
          >
            <span className="card__text--link-text">
              <span className="fs-unmask">{target.title}</span>
            </span>
          </h2>
        </AuthorizationResourceLink>

        {renderCardActivity(props)}
      </div>
      <div className="card__footer">
        <div className="card__stats">
          {stats.getStatsForGroup(card, t) || /*nbsp*/ ' '}
        </div>
        <div className="card__followers">
          {renderCardFollower(props, totalCount, isLearning)}
        </div>
        <div className="card__action">
          <SmartJoinGroupButton resource={card} group={target} wide={true} />
        </div>
      </div>
    </div>
  )
}

const renderCardActivity = ({ card }) => {
  const { t } = useTranslation('group')

  if (card.new_activity_count) {
    return (
      <span className="badge badge--wide badge--new">
        {t('group.new_activity_count', { count: card.new_activity_count })}
      </span>
    )
  }
  return null
}

export default withUserLookup(GroupCard)
