import React from 'react'
import cs from 'classnames'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import MedShrLogo from '../medshr-logo'
import Image, { ImageType, Sizes } from '../Img/image'
import { Img, ImgType } from '../Img'
import { AuthorizationResourceLink } from '../../ui/AuthorizationResourceLink'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  actor?: any
  card?: any
  target?: any
  children?: React.ReactNode
  sideKick?: React.ReactNode
  customeClass?: string
  group?: boolean
  square?: boolean
  src?: string
  alt?: string
  verb?: string
  wildcard?: any
}

const imageProps = {
  height: 60,
  width: 60,
  size: 'profile-thumb' as Sizes,
  alt: 'avatar',
}
const imgProps: ImgType = { height: 60, width: 60, alt: 'avatar' }
const placeHolderLogo = (): React.ReactElement => (
  <div className="image-placeholder--thumb">
    <MedShrLogo width={40} />
  </div>
)

class MediaBox extends React.Component<Props> {
  static defaultProps = {
    groups: false,
  }

  get mediaType() {
    const { card, target, actor, src, verb } = this.props
    const [hasCard, hasTarget, hasActor, hasSrc] = [card, target, actor, src]
    let result = 'none'

    if (hasCard && hasTarget) {
      if (verb && verb === 'connection_published_case') {
        result = 'connection_published_case'
      } else if (verb && verb === 'reject-group') {
        result = 'withTarget'
      } else result = 'default'
    } else if (hasActor) {
      if (verb && verb === 'connection_published_case') {
        result = 'connection_published_case'
      } else result = 'withActor'
    } else if (hasTarget) {
      if (verb && verb === 'connection_published_case') {
        result = 'connection_published_case'
      } else result = 'withTarget'
    } else if (hasSrc) {
      result = 'withSource'
    }

    return result
  }

  selectToUrl({ card, target, actor, src }: any): any {
    switch (this.mediaType) {
      case 'default':
        return card.actor
      case 'withWildcard':
        return actor
      case 'withActor':
        return actor
      case 'withTarget':
        return target
      case 'connection_published_case':
        return src || target
      case 'withSource':
        return src
    }
  }

  selectImgUrl({ imgUrl, src, wildcard }: any) {
    switch (this.mediaType) {
      case 'default':
      case 'withActor':
      case 'withTarget':
      case 'connection_published_case':
        return imgUrl || wildcard
      case 'withSource':
        return src || wildcard
      case 'withWildcard':
        return wildcard
      default:
        return ''
    }
  }

  renderMedia({
    card,
    target,
    actor,
    group,
    src,
    alt,
    verb,
    wildcard,
    square,
  }: any): any {
    const mediaType = this.mediaType
    const link = this.selectToUrl({ card, target, actor, src })
    const targetImgUrl = this.selectImgUrl({
      imgUrl: target ? target.image_url : actor ? actor.avatar_url : null,
      src,
      wildcard: wildcard ? wildcard.avatar_url : '',
    })
    let component = placeHolderLogo()

    const maskClass = target?.type === 'case' ? 'fs-exclude' : 'fs-unmask'

    if (mediaType === 'withSource') {
      return (
        <Img
          {...imgProps}
          src={targetImgUrl}
          alt={alt}
          className={cs(maskClass, {
            media__img: true,
            'image--profile-thumb': !square,
          })}
        />
      )
    }

    if (!!targetImgUrl) {
      component = (
        <Image
          {...imageProps}
          url={targetImgUrl}
          className={cs(maskClass, {
            'image--group-profile': group || verb === 'reject-group',
          })}
        />
      )
    }

    if (
      mediaType === 'withTarget' ||
      mediaType === 'connection_published_case'
    ) {
      if (group && targetImgUrl) {
        component = (
          <Image
            {...imageProps}
            url={targetImgUrl}
            className={cs(maskClass, {
              'image--group-profile': group && !!targetImgUrl,
            })}
          />
        )
      }
    }

    return (
      <AuthorizationResourceLink className="media__img" to={link}>
        {component}
      </AuthorizationResourceLink>
    )
  }

  render() {
    const { children, sideKick, customeClass } = this.props
    const containerClasses: string = cs(
      'media listing__item activity fs-unmask',
      customeClass
    )

    return (
      <div className={containerClasses}>
        {this.renderMedia(this.props)}
        <div className="media__body">{children}</div>

        {sideKick && sideKick}
      </div>
    )
  }
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default MediaBox
