import { useTranslation } from 'next-i18next'
import React from 'react'

import ResourceLink from '../../resource-link'
import SmartJoinGroupButton from '../../buttons/smart-join-group'
import MediaBox from '../MediaBox'

const GroupRequestAccepted = ({ card }) => {
  const { resource } = card
  const target = resource.target
  const sideKickBtn = (
    <SmartJoinGroupButton className="btn--iconic" group={target} />
  )

  const { t } = useTranslation('updates')

  return (
    <MediaBox
      card={card}
      target={target}
      sideKick={sideKickBtn}
      customeClass="activity--group activity--group-accepted"
    >
      <ResourceLink className="activity__title" to={target}>
        {target.title}
      </ResourceLink>
      <div className="activity__reason">
        {t('updates.institution_request_accepted')}
      </div>
    </MediaBox>
  )
}

export default GroupRequestAccepted
