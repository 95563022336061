import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { withUser } from '../../hoc/with-user'
import * as console from '../../lib/console'
import { withUserLookup } from '../../modules/entities/components/user-lookup'
import { CardType } from '../../types/caseTypes'
import { AuthenticatedUser, BasicUser } from '../../types/userTypes'
import PollIcon from '../../ui/icons/Poll'
import { AuthorizationResourceLink } from '../AuthorizationResourceLink'
import { Button } from '../buttons'
import LinkButton from '../buttons/link'
import SmartFollowButton from '../buttons/smart-follow'
import SmartInviteColleaguesButton from '../buttons/smart-invite-colleagues'
import FaceList from '../face-list'
import ProfileBasic from '../Profile/profile-basic'
import * as stats from './entry-stats'
import { getSizeStringWithPixelDensity } from '../../ui/Img/image'

interface FeedEntryType {
  card?: CardType
  currentUser?: AuthenticatedUser
  userMap?: any
  className?: string
  resourceContext?: any
  showConnectButton: boolean
  isLearning: boolean
}

const FeedEntry = ({
  card,
  userMap,
  className,
  resourceContext,
  currentUser,
  showConnectButton = true,
  isLearning,
}: FeedEntryType) => {
  const { t } = useTranslation('card')

  const containerClasses = cs(
    className,
    'fs-unmask',
    'card',
    `card--${card.target.type}`,
    {
      'card--without-actions': !showConnectButton,
      'card--without-followers': !!currentUser?.has_group_restrictions,
    }
  )

  const user: BasicUser = userMap[card.target.created_by] // eslint-disable-line

  const inlineStyle: any = {
    backgroundImage: card.target.image_url // eslint-disable-line
      ? `url(${card.target.image_url.replace(
          '{image_size}',
          getSizeStringWithPixelDensity('feed-image')
        )})`
      : 'none',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  }

  if (!user) {
    console.log('Missing creator', card)
    return null
  }

  return (
    <div className={containerClasses}>
      <ProfileBasic
        user={user}
        className="card__profile"
        isLearning={isLearning}
      />
      <div className="card__resource">
        <AuthorizationResourceLink
          className="card__text-link "
          to={card.target}
          resourceContext={resourceContext}
          isLearning={isLearning}
        >
          <h2 className="card__title">
            <span
              className="card__title-background fs-exclude"
              style={inlineStyle}
            ></span>
            <span className="card__text--link-text ">
              <span className="fs-unmask">
                {card.target.title || card.target.placeholder_title}
              </span>
            </span>
          </h2>
        </AuthorizationResourceLink>

        {card.new_comment_count ? (
          <span className="badge badge--wide badge--new ">
            {t('case:card.new_comment_count_label', {
              count: card.new_comment_count,
            })}
          </span>
        ) : card.comment_count ? (
          <span className="badge badge--wide badge--seen ">
            {t('case:card.comment_count_label', {
              count: card.comment_count as number,
            })}
          </span>
        ) : null}
        {card.target.poll ? <PollIcon className="card__type-icon " /> : null}
      </div>
      <div className="card__footer ">
        {card.reason_html ? (
          <div
            className="card__reason"
            dangerouslySetInnerHTML={{
              __html: card.reason_html.replace(/<\/?p>/g, ''),
            }}
          />
        ) : (
          <div className="card__reason">{card.reason ? card.reason : ''}</div>
        )}
        <div className="card__stats">
          {stats.getStatsForCase(card, t) || /*nbsp*/ ' '}
        </div>
        <div className="card__followers">
          {card.interested_users &&
          card.interested_users.length &&
          !currentUser?.has_group_restrictions ? (
            <FaceList
              users={card.interested_users
                .map((id: any): any => userMap[id])
                .filter((user: any): any => !!user)}
              count={4}
              total={
                card.follower_count ||
                (card.interested_users && card.interested_users.length)
              }
              isLearning={isLearning}
            />
          ) : null}
        </div>
        {showConnectButton && renderConnectionBtn(card, user, currentUser)}
      </div>
    </div>
  )
}

function renderConnectionBtn(
  card: CardType,
  user: BasicUser,
  currentUser?: AuthenticatedUser
): any {
  const { t } = useTranslation('case')

  if (
    user.id === currentUser?.id &&
    (!card.target.can_share || card.target.visibility === 'public')
  ) {
    return (
      <div className="card__action">
        <AuthorizationResourceLink to={card.target}>
          <Button wide>{t('case.view_case')}</Button>
        </AuthorizationResourceLink>
      </div>
    )
  }
  const ButtonComponent = currentUser
    ? user.id === currentUser.id &&
      card.target.can_share &&
      card.target.visibility !== 'public'
      ? SmartInviteColleaguesButton
      : SmartFollowButton
    : ViewCaseButton

  return (
    <div className="card__action">
      <ButtonComponent currentUser={currentUser} resource={card.target} wide />
    </div>
  )
}

const ViewCaseButton = ({ resource, wide }) => (
  <LinkButton forceReload wide={wide} href={`/cases/${resource.id}`}>
    View post
  </LinkButton>
)

export default withUserLookup(withUser(FeedEntry, FeedEntry), [
  'card.target.created_by',
  'card.interested_users',
])
