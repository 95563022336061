import cs from 'classnames'
import Link from 'next/link'
import React from 'react'

import Image from '../../Img/image'

type Props = {
  card: any
  getUserForID: Function
  className?: string
}

// strip protocol and domain from url for local dev:staging convenience:
const filterURL = url => url.replace(/^https:\/\/[^\/]+/, '')

const LearningModuleRow = ({
  card,
  getUserForID,
  className,
}: Props): React.ReactElement => {
  const module = card.target

  const cardRowClass: string = `card-row card-row--${module.type}`
  const classes: string = cs(cardRowClass, {
    className: className,
  })

  return (
    <div className={classes}>
      <div className="media">
        {module.image_url ? (
          <Link href={filterURL(module.url)} className="media__img">
            <Image
              url={module.image_url}
              size="small"
              className="card-row__image"
            />
          </Link>
        ) : null}

        <div className="media__body">
          <Link
            href={filterURL(module.url)}
            className="card-row__title media__title card__text-link"
          >
            {module.title}
          </Link>
          {module.series_title ? (
            <p className="card-row__creator media__info">
              <span>
                <span style={{ fontSize: 12 }}>
                  <b>Series:</b>{' '}
                </span>
                <span style={{ fontSize: 12 }}>{module.series_title}</span>
              </span>

              {module.publisher ? (
                <>
                  <br />
                  <span>
                    <span style={{ fontSize: 12 }}>
                      <b>{module.publisher.title}</b>{' '}
                    </span>
                    <span style={{ fontSize: 12 }}>
                      {module.publisher.subtitle}
                    </span>
                  </span>
                </>
              ) : null}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default LearningModuleRow
