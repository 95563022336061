import { useTranslation } from 'next-i18next'
import cs from 'classnames'
import React from 'react'

import { withUser } from '../../../hoc/with-user'
import { event } from '../../../modules/analytics/track'
import { CardType } from '../../../types/caseTypes'
import { Image } from '../../../ui/Img'

import style from './styles/AdImage.module.scss'

interface Props {
  card?: CardType
}

const imageSize = 'case-large'

const AdImage = ({ card }: Props): React.ReactElement => {
  const { target } = card

  const onClick = () => {
    event('Advertising', 'Tap', {
      label: card.target.id,
    })
  }

  const styles = { maxWidth: 320 }
  if (
    card.target.width &&
    card.target.height &&
    card.target.height <= card.target.width / 2
  ) {
    styles.maxWidth = card.target.width
  }

  const { t } = useTranslation('feed')

  return (
    <div className={cs(style.container, 'fs-unmask')} style={styles}>
      <div className={style.imageType}>
        {card.target.reason_html ? (
          <div
            dangerouslySetInnerHTML={{
              __html: card.target.reason_html.replace(/<\/?p>/g, ''),
            }}
          />
        ) : card.target.reason ? (
          card.target.reason
        ) : (
          ''
        )}
      </div>
      <div className={style.imgWrapper}>
        <a
          href={target.url}
          target="_blank"
          rel="noreferrer noopener"
          onClick={onClick}
        >
          <Image
            adaptive
            url={target.image_url}
            size={imageSize}
            alt={t('feed.adimage.ads')}
          />
        </a>
      </div>
    </div>
  )
}

export default withUser(AdImage)
