import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { withUser } from '../../../hoc/with-user'
import { event } from '../../../modules/analytics/track'
import { withUserLookup } from '../../../modules/entities/components/user-lookup'
import { useGroup } from '../../../modules/entities/hooks'
import { AuthenticatedUser, BasicUser } from '../../../types/userTypes'
import { Button } from '../../../ui/buttons'
import { rawTextTrimmer } from '../../../ui/formatters/text'
import ProfileBasic from '../../Profile/profile-basic'
import * as stats from '../entry-stats'
import { getSizeStringWithPixelDensity } from '../../../ui/Img/image'
import { isExternalURL } from '../../../url'

interface Props {
  card?: any
  currentUser?: AuthenticatedUser
  userMap?: any
  className?: string
  resourceContext?: any
}

const imageSize = 'case-large'
const ImageSizeGroup = 'm-group-large'

const FeedEntry = ({ card, className, userMap }: Props): React.ReactElement => {
  const { t } = useTranslation('feed')
  const { target } = card
  const user: BasicUser = userMap[card.target.created_by]
  const isGroupAd = target.is_group_ad
  const containerClasses = cs(
    className,
    'fs-unmask',
    'card',
    `card--${card.target.type}`
  )

  const imageUrl = card.target.image_url.replace(
    '{image_size}',
    getSizeStringWithPixelDensity(isGroupAd ? ImageSizeGroup : imageSize)
  )

  const inlineStyle = {
    backgroundImage: card.target.image_url // eslint-disable-line
      ? `url(${imageUrl})`
      : 'none',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  }

  const onClick = () => {
    event('Advertising', 'Tap', {
      label: target.id,
    })
  }

  const group = !!isGroupAd && useGroup(target.id)

  const linkProps: any = { href: target.url }
  if (isExternalURL(target.url)) {
    linkProps['target'] = '_blank'
    linkProps['rel'] = 'noreferrer noopener'
  }

  return (
    <div className={containerClasses}>
      <ProfileBasic user={user} className="card__profile" advert />
      <div className="card__resource">
        <a {...linkProps} onClick={onClick}>
          <div className="card__title" style={inlineStyle}>
            {target.title?.length ? (
              <span className="card__text--link-text">{target.title}</span>
            ) : null}
          </div>
        </a>
      </div>

      <div className="card__footer" style={{ minHeight: 141 }}>
        {card.target.reason_html ? (
          <div
            className="card__reason"
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{
              __html: card.target.reason_html.replace(/<\/?p>/g, ''),
            }}
          />
        ) : (
          <div className="card__reason">
            {card.target.reason ? card.target.reason : ''}
          </div>
        )}
        <div className="card__followers" />
        {renderConnectionBtn(card.target.url, target.cta, onClick)}
      </div>
    </div>
  )
}

function renderConnectionBtn(
  link: string,
  cta: string,
  onClick: Function
): any {
  return (
    <div className="card__action">
      <Button block link={link} onClick={() => onClick()}>
        {rawTextTrimmer({ text: cta, maxLetters: 16 })}
      </Button>
    </div>
  )
}

export default withUserLookup(withUser(FeedEntry), [
  'card.target.created_by',
  'card.interested_users',
])

// export default injectIntl(
//   withUserLookup(withUser(FeedEntry), [
//     'card.target.created_by',
//     'card.interested_users',
//   ])
// )
