import { useTranslation } from 'next-i18next'
import React from 'react'

import ResourceLink from '../../resource-link'
import SmartFollowButton from '../../buttons/smart-follow'
import MediaBox from '../MediaBox'
import style from './styles/flex.module.scss'

type Props = {
  card: any
  isMobile?: boolean
}

const CaseShared = ({ card }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const { resource, actor } = card
  const target = resource.target
  const creator = actor
  const sideKickBtn = (
    <SmartFollowButton flex className="btn--iconic" resource={target} />
  )

  return (
    <MediaBox target={target} customeClass="activity--case activity--spacious">
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.headTitle}>
            <ResourceLink className="activity__title" to={target}>
              {target.title}
            </ResourceLink>
          </div>
          <div>{sideKickBtn}</div>
        </div>
        <div className="media__info profile-basic__job-title">
          {creator.name}, {creator.job_title}
        </div>
        <div className="activity__reason media__info">
          {t(`updates:updates.case_shared_${target.visibility}`)}
        </div>
      </div>
    </MediaBox>
  )
}

export default CaseShared
