import React, { useEffect } from 'react'
import loadMember from '../modules/entities/actions/member_load'
import * as entities from '../modules/entities/selectors'
import { connect } from '../react-redux'
import { UserEntryType } from '../types/userTypes'
import ProfileBasicWithConnect from './Profile/profile-basic-with-connect'

interface Props {
  user: number | string
  user_entry?: UserEntryType
  loadUser?: (id: string) => void
}

const SmartProfileBasicWithConnect = (props: Props) => {
  useEffect(() => {
    if (!props.user_entry) {
      props.loadUser(String(props.user))
    }
  }, [])

  return props.user_entry ? (
    <ProfileBasicWithConnect
      size="profile-thumb"
      show_institution
      {...props}
      user={props.user_entry}
    />
  ) : (
    <p>Loading Author...</p>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    user_entry: entities.selectUser(state, ownProps.user),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadUser: id => {
      dispatch(loadMember(id))
    },
  }
}

export default connect<Props>(
  mapStateToProps,
  mapDispatchToProps
)(SmartProfileBasicWithConnect)
