import { useTranslation } from 'next-i18next'
import React from 'react'

import ResourceLink from '../../resource-link'
import MediaBox from '../MediaBox'

type Props = {
  card: any
}

const GroupRequestRejected = ({ card }: Props): any => {
  const { resource } = card
  const { t } = useTranslation()

  return (
    <MediaBox
      card={card}
      verb={'reject-group'}
      target={resource.target}
      customeClass="activity--group activity--group-rejected"
    >
      <ResourceLink className="activity__title" to={resource.target}>
        {resource.target.title}
      </ResourceLink>
      <div className="activity__reason">
        {t('updates:updates.group_request_rejected')}
      </div>
    </MediaBox>
  )
}

export default GroupRequestRejected
