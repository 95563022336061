/*
 * Based on https://github.com/Pomax/react-component-visibility commit db9bb55
 *
 * Modified to add partial visibility tracking.
 *
 */

import React, { useEffect, useRef, useState } from 'react'
import filter from 'lodash/filter'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'

import { event } from '../modules/analytics/track'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const RATE_LIMIT = 25

const bands = [
  [0, 25],
  [25, 50],
  [50, 75],
  [75, 100],
  [100, 100],
]

const impressions = {}

const impressionKey = (feedName, id) => feedName + ':' + id

const startImpression = (feedName, id, item_type, position) => {
  // console.log('startImpression', feedName, item_type, id, position)
  impressions[impressionKey(feedName, id)] = {
    position,
    feedName,
    id,
    item_type,
    band: -1,
    bandActivated: null,
    timeInBands: [],
  }
}

const updateBand = (feedName, id, index) => {
  // console.log('updateBand', feedName, id, index)
  const impression = impressions[impressionKey(feedName, id)]
  if (impression && impression.band !== index) {
    const now = new Date().getTime()
    const oldIndex = impression.band
    if (oldIndex >= 0) {
      const duration = now - impression.bandActivated
      if (impression.timeInBands[oldIndex]) {
        impression.timeInBands[oldIndex] += duration / 1000
      } else {
        impression.timeInBands[oldIndex] = duration / 1000
      }
    }
    impression.band = index
    impression.bandActivated = now
  }
}

const updateImpression = (feedName, id, amountVisible) => {
  // console.log('updateImpression', feedName, id, amountVisible)
  const percentVisible = amountVisible * 100
  if (percentVisible <= 0) {
    updateBand(feedName, id, -1)
  } else {
    for (let i = 0; i < bands.length; ++i) {
      const band = bands[i]
      if (
        percentVisible >= band[0] &&
        (percentVisible < band[1] || (percentVisible == 100 && band[1] == 100))
      ) {
        updateBand(feedName, id, i)
        break
      }
    }
  }
}

const stopImpression = (feedName, id) => {
  // console.log('stopImpression', feedName, id)
  updateBand(feedName, id, -1)
  const impression = impressions[impressionKey(feedName, id)]
  delete impressions[id]
  postEvents(impression)
}

const postEvents = impression => {
  const times = filter(
    map(impression.timeInBands, (duration, band) => {
      return {
        duration: (1000000 * parseInt(duration || '0', 10)) / 1000000,
        band_start: bands[band][0],
        band_end: bands[band][1],
      }
    }),
    info => info.duration > 0
  )
  // console.log(
  //   'postEvents',
  //   impression.id,
  //   impression.item_type,
  //   `times: ${times.length}`
  // )

  if (times.length) {
    event('Feed Item', 'View', {
      item_uid: impression.id,
      item_type: impression.item_type,
      source_feed_index: impression.position,
      source_feed: impression.feedName,
      time_info: times,
    })
  }
}

let last_tracked_impressions = []
let last_tracked_impressions_count = 0
let track_timeout = 2000

function timedTrack() {
  const tracked_impressions = map(impressions, impression => [
    impression.id,
    impression.band,
  ])
  if (isEqual(last_tracked_impressions, tracked_impressions)) {
    ++last_tracked_impressions_count
    if (last_tracked_impressions_count > 6) {
      return
    }
    track_timeout = 10000
  } else {
    track_timeout = 2000
    last_tracked_impressions_count = 0
  }
  last_tracked_impressions = tracked_impressions

  forEach(impressions, impression => {
    const b = impression.band
    updateBand(impression.feedName, impression.id, -1)
    postEvents(impression)
    impression.band = b
    impression.bandActivated = new Date().getTime()
    impression.timeInBands = []
  })

  setTimeout(timedTrack, track_timeout)
}

let startedTimer = false
function startTimer() {
  if (!startedTimer) {
    setTimeout(timedTrack, 2000)
    startedTimer = true
  }
}

/**
 * Check whether a component is in view based on its DOM node,
 * checking for both vertical and horizontal in-view-ness, as
 * well as whether or not it's invisible due to CSS rules based
 * on opacity:0 or visibility:hidden.
 */
const checkComponentVisibility = (
  domnode: Element,
  currentlyVisible: boolean
) => {
  if (domnode) {
    const gcs = window.getComputedStyle(domnode),
      dims = domnode.getBoundingClientRect(),
      h = window.innerHeight,
      w = window.innerWidth,
      // are we vertically visible?
      topVisible = 0 <= dims.top && dims.top <= h,
      bottomVisible = 0 <= dims.bottom && dims.bottom <= h,
      verticallyVisible = topVisible || bottomVisible,
      // also, are we horizontally visible?
      leftVisible = 0 <= dims.left && dims.left <= w,
      rightVisible = 0 <= dims.right && dims.right <= w,
      horizontallyVisible = leftVisible || rightVisible
    // we're only visible if both of those are true.
    let visible = horizontallyVisible && verticallyVisible

    // but let's be fair: if we're opacity: 0 or
    // visibility: hidden, or browser window is minimized we're not visible at all.
    if (visible) {
      const isDocHidden = document.hidden
      const isElementNotDisplayed = gcs.getPropertyValue('display') === 'none'
      const elementHasZeroOpacity =
        parseInt(gcs.getPropertyValue('opacity'), 10) === 0
      const isElementHidden = gcs.getPropertyValue('visibility') === 'hidden'
      visible =
        visible &&
        !(
          isDocHidden ||
          isElementNotDisplayed ||
          elementHasZeroOpacity ||
          isElementHidden
        )
    }

    // at this point, if our visibility is not what we expected,
    // update our state so that we can trigger whatever needs to
    // happen.
    if (visible || visible !== currentlyVisible) {
      const top = Math.max(dims.top, 0),
        bottom = Math.min(dims.bottom, h),
        left = Math.max(dims.left, 0),
        right = Math.min(dims.right, w)
      if (bottom !== top && left !== right) {
        const amountVisible =
          ((bottom - top) * (right - left)) /
          ((dims.right - dims.left) * (dims.bottom - dims.top))
        return { visible, amountVisible }
      }
    } else {
      // If the domnode itself is not visible, check its children
      for (const child of domnode.children) {
        const childVisibility = checkComponentVisibility(child, false)
        if (childVisibility && childVisibility.visible) {
          return childVisibility
        }
      }
    }
  }
}

export type TrackVisibilityProps = {
  source_feed: string
  item_uid: string
  item_type: string
  item_index: number
  children: React.ReactNode
}

export const TrackVisibility = (props: TrackVisibilityProps) => {
  const [state, setState] = useState({ visible: false, amountVisible: 0 })
  const [timer, setTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >()
  const [lock, setLock] = useState(false)
  const [schedule, setSchedule] = useState(false)

  const node = useRef(null)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return console.error("This environment lacks 'window' support.")
    }

    if (typeof document === 'undefined') {
      return console.error("This environment lacks 'document' support.")
    }

    const domnode = node.current?.nextSibling

    if (domnode) {
      startTimer()
      const r = enableVisibilityHandling(domnode, true)
      startImpression(
        props.source_feed,
        props.item_uid,
        props.item_type,
        props.item_index
      )

      return () => {
        disableVisibilityHandling(domnode, r)
        stopImpression(props.source_feed, props.item_uid)
        setLock(false)
        setSchedule(false)
      }
    }
  }, [node.current])

  const componentVisibilityChanged = ({ amountVisible }) => {
    updateImpression(props.source_feed, props.item_uid, amountVisible)
  }

  /**
   * This can be called to manually turn on visibility handling, if at
   * some point it got turned off. Call this without arguments to turn
   * listening on, or with argument "true" to turn listening on and
   * immediately check whether this element is already visible or not.
   */
  const enableVisibilityHandling = (domnode: Element, checkNow?: boolean) => {
    const _rcv_fn = function () {
      if (lock) {
        setSchedule(true)
        return
      }
      // setLock(true)
      checkComponentVisibilityState(domnode)

      setTimer(
        setTimeout(function () {
          setLock(false)
          if (schedule) {
            setSchedule(false)
            checkComponentVisibilityState(domnode)
          }
        }, RATE_LIMIT)
      )
    }

    let bodyNode = domnode
    /* Adding scroll listeners to all element's parents */
    while (bodyNode && bodyNode.nodeName !== 'BODY' && bodyNode.parentElement) {
      bodyNode = bodyNode.parentElement
      bodyNode.addEventListener('scroll', _rcv_fn)
    }
    /* Adding listeners to page events */
    document.addEventListener('visibilitychange', _rcv_fn)
    document.addEventListener('scroll', _rcv_fn)
    window.addEventListener('resize', _rcv_fn)

    if (checkNow) {
      _rcv_fn()
    }

    return _rcv_fn
  }

  /**
   * This can be called to manually turn off visibility handling. This
   * is particularly handy when you're running it on a lot of components
   * and you only really need to do something once, like loading in
   * static assets on first-time-in-view-ness (that's a word, right?).
   */
  const disableVisibilityHandling = (
    domnode: Element,
    _rcv_fn?: () => void
  ) => {
    clearTimeout(timer)
    if (_rcv_fn) {
      let bodyNode = domnode
      while (
        bodyNode &&
        bodyNode.nodeName !== 'BODY' &&
        bodyNode.parentElement
      ) {
        bodyNode = bodyNode.parentElement
        bodyNode.removeEventListener('scroll', _rcv_fn)
      }

      document.removeEventListener('visibilitychange', _rcv_fn)
      document.removeEventListener('scroll', _rcv_fn)
      window.removeEventListener('resize', _rcv_fn)
    }
  }

  const checkComponentVisibilityState = (domnode: Element) => {
    const res = checkComponentVisibility(domnode, state.visible)
    if (res) {
      // set State first:
      setState(res)
      // then notify the component the value was changed:
      componentVisibilityChanged(res)
    }
  }

  return (
    <>
      <div ref={node} />
      {props.children}
    </>
  )
}
