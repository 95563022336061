import take from 'lodash/take'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'

import { selectAuthenticatedUser } from '../../../modules/auth/selectors'
import { selectSpecialtyMapper } from '../../../modules/categories/selectors'
import SmartJoinGroupButton from '../../buttons/smart-join-group'
import ResourceLink from '../../resource-link'
import MediaBox from '../MediaBox'
import { FeedCard } from './flowTypes'
import style from './styles/group-new.module.scss'

interface Props {
  card: FeedCard
  currentUser: any
  specialties: any
  isMobile?: boolean
}

const GroupNewComponent = ({
  card,
  currentUser,
  specialties,
}: Props): React.ReactElement => {
  const { resource, verb, exam, actor } = card
  const target = resource.target
  const sideKickBtn = (
    <SmartJoinGroupButton flex resource={resource} group={target} brief />
  )

  let message

  const { t } = useTranslation('updates')

  if (verb === 'group_new_for_exam') {
    message = t('updates.group_new_for_exam', { exam })
  } else if (verb === 'group_new_in_subspecialty') {
    const subspecialty = take(
      specialties.getCommonSubspecialtyLabels(
        currentUser.specialty,
        target.category
      ),
      2
    ).join(', ')
    message = t('updates.group_new_in_subspecialty', { subspecialty })
  } else if (verb === 'group_new_in_specialty') {
    const specialty = take(
      specialties.getCommonSpecialtyLabels(
        currentUser.specialty,
        target.category
      ),
      2
    ).join(', ')
    message = t('updates.group_new_in_specialty', { specialty })
  } else {
    message = t('updates.group_new')
  }

  return (
    <MediaBox actor={actor} target={target} verb={verb} group>
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.headTitle}>
            <ResourceLink className="activity__title" to={target}>
              {target.title}
            </ResourceLink>
          </div>
          <div>{sideKickBtn}</div>
        </div>

        <div className="activity__reason">
          <span>{message}</span>
        </div>
      </div>
    </MediaBox>
  )
}

export const GroupNew = connect((state: any): any => ({
  currentUser: selectAuthenticatedUser(state),
  specialties: selectSpecialtyMapper(state),
}))(GroupNewComponent)
