import cs from 'classnames'
import get from 'lodash/get'
import { useRouter } from 'next/router'
import React, { createElement } from 'react'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import { error } from '../../modules/analytics/track'
import { useSelector } from '../../react-redux'
import { Item, Layout } from '../layout'
import { TrackVisibility } from '../track-visibility'
import { AdCard, AdImage } from './Ads'
import CaseCard from './case'
// ////////////////////////////////////////////////////////////////// FLOW TYPES
import { CardListProps, CardProps } from './flowTypes'
import GroupCard from './group'
import UserCard from './user'
import LearningModule from './learning_module'
import LearningSeries from './learning_series'
import GroupManageRow from './manage/group'
import MyCaseCard from './my/case'
import AdCardRow from './rows/ad_card'
import CaseRow from './rows/case'
import CategoryRow from './rows/category'
import GroupRow from './rows/group'
import LearningModuleRow from './rows/learning_module'
import LearningSeriesRow from './rows/learning_series'
import UserRow from './rows/user'
import CaseCommented from './updates/case-commented'
import CaseShared from './updates/case-shared'
import ConnectionAccepted from './updates/connection-accepted'
import ConnectionInvite from './updates/connection-invite'
import ConnectionInviteAccepted from './updates/connection-invite-accepted'
import { ConnectionJoinedGroup } from './updates/connection-joined-group'
import ConnectionMadeConnection from './updates/connection-made-connection'
import ConnectionPublishedCase from './updates/connection-published-case'
import GroupAdminJoinRequest from './updates/group-admin-join-request'
import GroupInvite from './updates/group-invite'
import GroupInviteAccepted from './updates/group-invite-accepted'
import GroupInviteAdmin from './updates/group-invite-admin'
import { GroupNew } from './updates/group-new'
import GroupRequestAccepted from './updates/group-request-accepted'
import GroupRequestRejected from './updates/group-request-rejected'
import InstitutionAdminJoinRequest from './updates/institution-admin-join-request'
import InstitutionRequestAccepted from './updates/institution-request-accepted'
import InstitutionRequestRejected from './updates/institution-request-rejected'
import {
  MemberFollowingCase,
  MemberNewCommentor,
  MemberNewDentist,
  MemberNewInInstitution,
  MemberNewInSubSpecialty,
  MemberNewNurse,
  MemberNewStudent,
} from './updates/member-new'
import {
  OtherJoinedGroup,
  OtherJoinedThisGroup,
} from './updates/other-joined-group'
import PinnedMessage from './updates/PinnedMessage'
import Welcome from './updates/Welcome'
import { SidebarResourceImage, SidebarResourceInfo } from './SidebarResources'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const networkUpdateComponents = {
  connection_published_case: ConnectionPublishedCase,
  connection_made_connection: ConnectionMadeConnection,
  connection_accepted: ConnectionAccepted,
  connection_invite: ConnectionInvite,
  connection_invite_accepted: ConnectionInviteAccepted,

  group_invite: GroupInvite,
  group_invite_accepted: GroupInviteAccepted,

  group_invite_admin: GroupInviteAdmin,
  group_invite_admin_accepted: GroupInviteAccepted,

  institution_invite_admin: GroupInviteAdmin,
  institution_invite_accepted: GroupInviteAccepted,
  institution_invite: GroupInvite,

  connection_joined_group: ConnectionJoinedGroup,
  connection_joined_this_group: OtherJoinedThisGroup, // TODO: rename this component as it can be a connection not always "other"

  other_joined_group: OtherJoinedGroup,
  other_joined_this_group: OtherJoinedThisGroup,

  group_published_case: CaseShared,
  group_commented_case: CaseCommented,

  member_new_dentist: MemberNewDentist,
  member_new_nurse: MemberNewNurse,
  member_new_student: MemberNewStudent,
  member_new_from_institution: MemberNewInInstitution,
  member_new_from_subspecialty: MemberNewInSubSpecialty,
  member_comment_for_you: MemberNewCommentor,
  member_case_youre_following: MemberFollowingCase,

  group_new_for_exam: GroupNew,
  group_new_in_specialty: GroupNew,
  group_new_in_subspecialty: GroupNew,
  group_new: GroupNew,

  group_request_accepted: GroupRequestAccepted,
  group_request_rejected: GroupRequestRejected,

  institution_request_accepted: InstitutionRequestAccepted,
  institution_request_rejected: InstitutionRequestRejected,

  group_admin_join_request: GroupAdminJoinRequest,
  institution_admin_join_request: InstitutionAdminJoinRequest,

  sidebar_resource_image: SidebarResourceImage,
  sidebar_resource_info: SidebarResourceInfo,

  welcome: Welcome,

  pinned_message: PinnedMessage,
}

const cardTypes = {
  card: {
    case: CaseCard,
    user: UserCard,
    group: GroupCard,
    institution: GroupCard,
    ad_image: AdImage,
    ad_card: AdCard,

    learning_series: LearningSeries,
    learning_module: LearningModule,
  },
  my: { case: MyCaseCard },
  row: {
    ad_card: AdCardRow,
    case: CaseRow,
    group: GroupRow,
    institution: GroupRow,
    user: UserRow,
    category: CategoryRow,
    learning_series: LearningSeriesRow,
    learning_module: LearningModuleRow,
  },
  manage: {
    group: GroupManageRow,
    institution: GroupManageRow,
  },
}

// //////////////////////////////////////////////////////////////////////// CARD
export const Card = ({
  card,
  cardStyle,
  cardProps,
  cardListComponent,
}: CardProps) => {
  if (card.verb) {
    const component = networkUpdateComponents[card.verb]

    if (component) {
      return (
        <div className={card.verb}>
          {createElement(component, { card, ...cardProps, cardListComponent })}
        </div>
      )
    }
    if (card.verb !== 'pinned_message') {
      error(`No component for ${card.verb}`, { card })
    }

    return null
  }

  if (!card.target) {
    error('No target on card', { card })
    return null
  }

  if (!cardTypes[cardStyle]) {
    error('Unknown cardStyle', { card })
  }

  const component = cardTypes[cardStyle][card.target.type]

  if (component) {
    // console.log(createElement(component, { card, ...cardProps, cardListComponent }))
    return createElement(component, { card, ...cardProps, cardListComponent })
  }

  error('Unsupported card type', { card })

  return null
}

// /////////////////////////////////////////////////////////////////// CARD LIST
const CardList = (props: CardListProps) => {
  const defaultProps = {
    trackImpressions: false,
    cardStyle: 'card',
    cardProps: {},
    banners: [],
  }

  const {
    trackImpressions,
    feedName,
    cards,
    banners,
    className,
    layout,
    cardStyle,
    cardProps,
  } = { ...defaultProps, ...props }

  const browser = useSelector(state => state.browser)
  const router = useRouter()
  const routerPath = router.pathname

  const mediaType = browser.mediaType
  const mediaOrientation = browser.orientation

  return (
    <Layout className={cs('cards', className)}>
      {renderCards(
        trackImpressions,
        feedName,
        cards,
        banners,
        mediaType,
        mediaOrientation,
        layout,
        cardStyle,
        cardProps,
        routerPath
      )}
    </Layout>
  )
}

// /////////////////////////////////////////////////////////// RENDER CARDS ITEM
function renderCards(
  trackImpressions,
  feedName,
  cards,
  banners,
  mediaType,
  mediaOrientation,
  layout,
  cardStyle,
  cardProps,
  routerPath
) {
  const items = cards
    .filter(card => card && (!!card.target || !!card.verb))
    .map((card, index) => {
      const keyId =
        feedName +
        '-' +
        (get(card, 'id', false)
          ? card.id || (card.target ? card.target.id : index)
          : Date.now() * Math.random())

      if (trackImpressions && feedName && card.target) {
        return (
          <Item layout={layout} key={keyId}>
            <TrackVisibility
              item_uid={card.target.id}
              item_type={card.target.type}
              item_index={index}
              source_feed={feedName}
            >
              <Card
                card={card}
                cardStyle={cardStyle}
                cardProps={cardProps}
                cardListComponent={CardList}
              />
            </TrackVisibility>
          </Item>
        )
      }

      if (!card) {
        console.error('No card at ' + index, cards)
        return null
      }

      return (
        <Item layout={layout} key={keyId}>
          <Card
            card={card}
            cardStyle={cardStyle}
            cardProps={cardProps}
            cardListComponent={CardList}
          />
        </Item>
      )
    })

  const isAvailable = isAvailableRoutes(routerPath, [
    '/',
    '/cases',
    '/cases/feed',
    '/cases/latest',
  ])
  if (banners.length && layout && isAvailable) {
    const mediaTypeOriented =
      mediaType === 'palm'
        ? layout[mediaType]
          ? mediaType
          : mediaType + '_' + mediaOrientation
        : mediaType
    const perRow = layout[mediaTypeOriented]
      ? layout[mediaTypeOriented]
      : layout['_']

    const initialSpacing = Math.max(3, perRow * 2)
    const normalSpacing = Math.max(4, perRow * 2)
    let ad_card_count = 0

    for (let i = 0; i < banners.length; ++i) {
      const card = banners[i]
      const insertAt =
        i === 0 ? initialSpacing : i * normalSpacing + initialSpacing
      const insertAtAdjustedForRow =
        ad_card_count + i + perRow * Math.ceil(insertAt / perRow)

      if (insertAtAdjustedForRow > cards.length + 1) {
        break
      }

      if (card.target.type === 'ad_card') ++ad_card_count

      items.splice(
        insertAtAdjustedForRow,
        0,
        <div
          className="layout__item u-1-of-1"
          key={
            get(card, 'id', false)
              ? `${feedName}${card.id}`
              : card.target
              ? `${feedName}${card.target.id}`
              : `${feedName}${card.id}_renderCards`
          }
        >
          <TrackVisibility
            item_uid={card.target.id}
            item_type={card.target.type}
            item_index={insertAt}
            source_feed={feedName}
          >
            <Card
              card={card}
              cardStyle={cardStyle}
              cardProps={cardProps}
              cardListComponent={CardList}
            />
          </TrackVisibility>
        </div>
      )
    }
  }

  return items
}

export function isAvailableRoutes(path: string, routes: Array<any>) {
  return routes.indexOf(path) >= 0
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default CardList
