import { useTranslation } from 'next-i18next'
import React from 'react'
import SmartJoinGroupButton from '../../buttons/smart-join-group'

import ResourceLink from '../../resource-link'
import MediaBox from '../MediaBox'

const GroupRequestAccepted = ({ card }) => {
  const { t } = useTranslation('updates')

  const { resource } = card
  const target = resource.target
  const sideKickBtn = (
    <SmartJoinGroupButton
      className="btn--iconic"
      resource={resource}
      group={target}
    />
  )

  return (
    <MediaBox
      sideKick={sideKickBtn}
      target={target}
      group
      customeClass="activity--group activity--group-accepted"
    >
      <ResourceLink className="activity__title" to={target}>
        {target.title}
      </ResourceLink>
      <div className="activity__reason">
        {t('updates.group_request_accepted')}
      </div>
    </MediaBox>
  )
}

export default GroupRequestAccepted
