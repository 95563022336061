import { useTranslation } from 'next-i18next'
import React from 'react'
import cs from 'classnames'

import ResourceLink from '../../resource-link'
import SmartFollowButton from '../../buttons/smart-follow'
import MediaBox from '../MediaBox'
import style from './styles/flex.module.scss'
import { FeedCard } from './flowTypes'

type Props = {
  card: FeedCard
  isMobile?: boolean
}

const ConnectionPublishedCaseVisibilityLang = {
  public: 'updates.connection_published_case_public2',
  private: 'updates.connection_published_case_private',
  colleagues: 'updates.connection_published_case_colleagues2',
  invite: 'updates.connection_published_case_invite',
}

const ConnectionPublishedCase = ({ card }: Props): React.ReactElement => {
  const { t } = useTranslation('updates')
  const { resource, verb } = card
  const target = resource.target
  const messageID =
    ConnectionPublishedCaseVisibilityLang[target.visibility] ||
    ConnectionPublishedCaseVisibilityLang['public']
  const showActor =
    target.visibility === 'private' || target.visibility === 'invite'
  const sideKickBtn = <SmartFollowButton flex resource={target} />
  const costumeClasses = cs('activity--case', {
    'activity--spacious': !showActor,
  })

  return (
    <MediaBox
      target={target}
      wildcard={card.actor}
      verb={verb}
      customeClass={costumeClasses}
      group
    >
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.headTitle}>
            <ResourceLink className="activity__title" to={target}>
              {target.title}
            </ResourceLink>
          </div>
          <div>{sideKickBtn}</div>
        </div>

        {showActor && (
          <div className="activity__actor">
            <ResourceLink to={card.actor}>{card.actor.name}</ResourceLink>
          </div>
        )}
        <div className="activity__reason">
          {messageID === 'updates.connection_published_case_public2' && (
            <ResourceLink to={card.actor}>{card.actor.name} </ResourceLink>
          )}

          {target.visibility === 'colleagues' ? (
            <React.Fragment>
              <ResourceLink to={card.actor}>{card.actor.name} </ResourceLink>
              {t(messageID)}
            </React.Fragment>
          ) : (
            t(messageID, { name: card.actor.name })
          )}
        </div>
      </div>
    </MediaBox>
  )
}

export default ConnectionPublishedCase
