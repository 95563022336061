import React from 'react'

import { PLACEHOLDER_IMG_SRC } from '../../components/Learning/learning-modules/rows/SeriesRow'
import SeriesRowCard from '../../components/Learning/learning-modules/rows/SeriesRowCard'
import { getImageURL } from '../../url'

type Props = {
  card: any
}

const Card = (props: Props) => {
  const series = props.card.target

  const commonRowData = {
    coverImage: series.image
      ? getImageURL('small', 'cms', encodeURI(series.image.path))
      : PLACEHOLDER_IMG_SRC,
  }

  return (
    <SeriesRowCard
      series={series}
      commonRowData={commonRowData}
      isFeed={true}
    />
  )
}

export default Card
