import { useTranslation } from 'next-i18next'
import React from 'react'

import ResourceLink from '../../resource-link'
import SmartConnectButton from '../../buttons/smart-connect'
import MediaBox from '../MediaBox'
import style from './styles/NewMember.module.scss'
import { FeedCard } from './flowTypes'

type Props = {
  card: FeedCard
  isMobile: boolean
  kind: string
  common_subspecialty_count: string | number
  Commentor: boolean
  InInstitution: boolean
  FollowingCase: boolean
}

const NewMember = ({
  card,
  kind,
  InInstitution,
  common_subspecialty_count,
  Commentor,
  FollowingCase,
  ...rest
}: Props): React.ReactElement => {
  const { t } = useTranslation('updates')

  const { resource, actor, verb }: any = card
  const target = resource.target
  const sideKickBtn = <SmartConnectButton flex user={actor} />

  return (
    <MediaBox
      target={target}
      wildcard={actor}
      verb={verb}
      customeClass="activity--member"
    >
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.headTitle}>
            {FollowingCase && (
              <ResourceLink
                className="activity__title"
                {...rest}
                to={resource.target}
              >
                {resource.target.title}
              </ResourceLink>
            )}
            {kind &&
              t(kind ? `updates.member_new_${kind}` : 'updates.member_new', {
                name: resource.target,
              })}
            {InInstitution && t('updates.member_new_from_institution')}
            {common_subspecialty_count &&
              t('updates.member_new_from_subspecialty', {
                count: parseInt(common_subspecialty_count as string),
              })}
            {Commentor && (
              <ResourceLink
                className="activity__title"
                {...rest}
                to={resource.target}
              >
                {resource.target.title}
              </ResourceLink>
            )}
          </div>
          <div>{sideKickBtn}</div>
        </div>

        <div className="activity__reason">
          {FollowingCase && 'You followed, '}
          <ResourceLink to={actor}>{actor.name}</ResourceLink>
          {FollowingCase && ' case'}
          {Commentor && t('updates.commenting_on')}
        </div>
      </div>
    </MediaBox>
  )
}

export default NewMember
