import React from 'react'

import ResourceLink from '../../resource-link'
import UserIcon from '../../icons/user'
import MediaBox from '../MediaBox'
import { useTranslation } from 'next-i18next'

interface Props {
  card: any
}

const ConnectionMadeConnection = ({ card }: Props): React.ReactElement => {
  const { resource, actor } = card
  const target = resource.target

  const { t } = useTranslation('updates')
  const { t: tMember } = useTranslation('member')

  return (
    <MediaBox actor={actor} customeClass="activity--member">
      <div className="activity__heading">
        <ResourceLink className="activity__title" to={card.actor}>
          {card.actor.name}
        </ResourceLink>
        <ResourceLink to={card.actor} className="text-icon-link">
          <span className="text-icon-link__text">
            {tMember('member.view_profile')}
          </span>
          <UserIcon />
        </ResourceLink>
      </div>
      <div className="activity__reason-prefix">
        {t('updates.connection_made_connection', {
          name: target.name,
          job_title: target.job_title,
        })}
      </div>
      <div className="activity__reason">
        <ResourceLink to={target}>
          {t('updates.connection_made_connection_with', {
            name: target.name,
            job_title: target.job_title,
          })}
        </ResourceLink>
      </div>
    </MediaBox>
  )
}

export default ConnectionMadeConnection
