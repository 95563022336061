import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

import TextFormatter from '../../formatters/text'
import { Image } from '../../Img'
import { FeedCard } from '../updates/flowTypes'

const imageSize = 'sidebar-resource-image-small'

const SidebarImage = ({ target, t }) => {
  return target.has_image ? (
    <div className="layout__item u-4-of-12" style={{ marginBottom: 10 }}>
      <Image
        adaptive
        url={target.image_url}
        size={imageSize}
        alt={t('updates.sidebar_resource_title', { title: target.title })}
      />
    </div>
  ) : null
}

function renderURL(target) {
  if (target.url != '' && target.link_lable != '') {
    return (
      <div className="sidebar-resource-info--link">
        <a href={target.url} target="_blank" rel="noreferrer noopener">
          {target.link_label}
        </a>
      </div>
    )
  }
}

interface Props {
  card: FeedCard
}

const SidebarResourceInfo = ({ card }: Props) => {
  const target = card.resource.target
  const messageContainerClasses = cs('layout__item', {
    'u-8-of-12': target.has_image,
  })

  const { t } = useTranslation('updates')

  return (
    <div className="listing__item activity-panel activity-panel--sidebar-resource-info">
      <div className="activity-panel__header dark-background">
        <h3 className="activity-panel__title">
          {t('updates.sidebar_resource_title', {
            title: target.title,
          })}
        </h3>
      </div>
      <div
        className="layout layout--tiny"
        style={{ padding: '0 10px', marginTop: 10 }}
      >
        <SidebarImage target={target} t={t} />
        <div className={messageContainerClasses} style={{ marginBottom: 0 }}>
          <TextFormatter text={target.description} />
        </div>
      </div>
      {renderURL(target)}
    </div>
  )
}

export default SidebarResourceInfo
