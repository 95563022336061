import { useTranslation, withTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'
import isArray from 'lodash/isArray'
import values from 'lodash/values'
import head from 'lodash/head'
import concat from 'lodash/concat'
import classNames from 'classnames'

import * as apiState from '../../../modules/api/selectors'
import * as authState from '../../../modules/auth/selectors'
import { selectSpecialtyMapper } from '../../../modules/categories/selectors'
import * as entityState from '../../../modules/entities/selectors'
import { withUserLookup } from '../../../modules/entities/components/user-lookup'
import connectTo from '../../../modules/entities/actions/connect'
import FaceList from '../../smart-face-list'
import ProfileBasic from '../../Profile/profile-basic'
import ActionButton from './ActionButton'

interface ConnectionInviteProps {
  commonConnections: string[]
  commonSpecialtyLabel?: string
  commonInstitution?: any
  getUserForID: (id) => any

  card: any
  accept: (resource) => void
  ignore: (resource) => void
  connected?: boolean
  ignored?: boolean
  accepting?: boolean
  ignoring?: boolean
}

const ConnectionInvite = (props: ConnectionInviteProps) => {
  const {
    card,
    connected,
    accepting,
    ignoring,
    ignore,
    commonConnections,
    commonSpecialtyLabel,
    commonInstitution,
    getUserForID,
  } = props
  const { t } = useTranslation('updates')
  const target = card.resource.target
  const actionMessages = {
    connected: 'updates.connection_invite_accepted',
    accepted: 'updates.connection_invite_accepting',
    accept: 'updates.connection_invite_accept',
  }
  const containerClasses = classNames('listing__item activity-panel', {
    'activity-panel--ignored': ignoring,
  })
  let onlyCommonConnection

  if (card.resource.common_colleagues_count === 1) {
    onlyCommonConnection = getUserForID(commonConnections[0])
  }

  return (
    <div className={containerClasses}>
      <div className="activity-panel__header dark-background">
        <h3 className="activity-panel__title">
          {t('updates:updates.connection_invite_title', {
            name: target.name,
            first_name: target.name.replace(/^([^ ]+).*$/, '$1'),
          })}
        </h3>
        <ActionButton messages={actionMessages} {...props} t={t} />
      </div>
      <div className="activity-panel__body">
        <div className="activity-panel__body-inner">
          <ProfileBasic
            user={target}
            size="profile-thumb"
            className="activity-panel__profile"
            show_institution
          />
        </div>
      </div>
      <div className="activity-panel__footer">
        <div className="card__stats">
          <FaceList
            users={commonConnections}
            count={3}
            total={commonConnections.length}
            plus_type="common_connections"
          />
          <span className="card__stats__numbers">
            {renderCardStatsNumber(
              onlyCommonConnection,
              commonSpecialtyLabel,
              commonInstitution,
              card.resource
            )}
          </span>
        </div>
        {!ignoring || (!connected && !accepting) ? (
          <button className="action-link" onClick={() => ignore(card.resource)}>
            {t('updates:updates.connection_invite_ignore')}
          </button>
        ) : null}
      </div>
    </div>
  )
}

/**
 * Render Cards Stats number
 * @param onlyCommonConnection
 * @param commonSpecialtyLabel
 * @param commonInstitution
 * @param resource
 * @returns {XML}
 */
const renderCardStatsNumber = (
  onlyCommonConnection,
  commonSpecialtyLabel,
  commonInstitution,
  resource
) => {
  const { t } = useTranslation('member')

  if (onlyCommonConnection && resource.common_colleagues_count) {
    return t('member.shared_connections', {
      count: resource.common_colleagues_count,
      name: onlyCommonConnection.name,
    })
  } else if (!resource.common_colleagues_count) {
    if (commonSpecialtyLabel) {
      return t('member.shared_specialty', { specialty: commonSpecialtyLabel })
    }
    if (commonInstitution) {
      return t('member.shared_institution', {
        specialty: commonInstitution.name,
      })
    }
  } else if (resource.common_colleagues_count <= 3) {
    return t('member.shared_connections', {
      count: resource.common_colleagues_count,
    })
  }
}

function mapStateToProps(state, props) {
  const resource = props.card.resource
  const user = authState.selectAuthenticatedUser(state)
  const specialties = selectSpecialtyMapper(state)

  return {
    connected: entityState.userHasConnection(state, resource.target.id),
    accepting: apiState.isRequestActive(
      state,
      connectTo(resource.target.type, resource.target.id, true)
    ),
    ignoring: apiState.isRequestActive(
      state,
      connectTo(resource.target.type, resource.target.id, false)
    ),
    commonConnections:
      resource.connections ||
      (isArray(resource.users) ? resource.users : values(resource.users)),
    commonSpecialtyLabel: head(
      concat(
        specialties.getCommonSubspecialtyLabels(
          user.specialty,
          resource.target.specialty
        ),
        specialties.getCommonSpecialtyLabels(
          user.specialty,
          resource.target.specialty
        )
      )
    ),
    commonInstitution:
      user.institutions &&
      -1 < user.institutions.indexOf(resource.target.main_institution)
        ? entityState.selectEntity(
            state,
            'institution',
            resource.target.main_institution
          )
        : undefined,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    accept: resource => {
      return dispatch(connectTo(resource.target.type, resource.target.id, true))
    },
    ignore: resource => {
      return dispatch(
        connectTo(resource.target.type, resource.target.id, false)
      )
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUserLookup(ConnectionInvite))
