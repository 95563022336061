import { isDABCaseEntry } from '../../modules/entities/posts'

// ///////////////////////////////////////////////////////// GET STATS FOR GROUP
export function getStatsForGroup(entry, t) {
  const stats = []
  const member_count =
    (entry?.colleague_member_count ?? 0) + (entry?.other_member_count ?? 0) ??
    entry?.member_count ??
    0

  if (member_count > 0) {
    stats.push(t('group:group.member_count', { count: member_count }))
  }

  if (entry?.cases_count_label) {
    stats.push(t(entry.cases_count_label))
  }
  return stats.join('  |  ') // these are non-breaking spaces
}

// ////////////////////////////////////////////////////////// GET STATS FOR CASE
export function getStatsForCase(entry, t) {
  const stats = []
  if (entry.view_count > 0 && !isDABCaseEntry(entry)) {
    stats.push(
      t('case:case.view_count_label', {
        count: entry.view_count,
      })
    )
  }
  if (entry.follower_count > 0) {
    stats.push(
      t('case:case.followers_count_label', {
        count: entry.follower_count,
      })
    )
  }
  return stats.join('  |  ') // these are non-breaking spaces
}
