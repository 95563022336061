import { withTranslation } from 'next-i18next'
import React from 'react'
import { connect } from 'react-redux'
import concat from 'lodash/concat'
import cs from 'classnames'
import get from 'lodash/get'

import * as apiState from '../../../modules/api/selectors'
import * as entityState from '../../../modules/entities/selectors'
import { selectIsMobile } from '../../../modules/responsive/selectors'
import { withUserLookup } from '../../../modules/entities/components/user-lookup'
import hideUpdate from '../../../modules/entities/actions/hideUpdate'
import { link_for_resource } from '../../../url'
import ProfileBasic from '../../Profile/profile-basic'
import { TextLink } from '../../link'
import ActionButton from './ActionButton'
import XIcon from '../../icons/x'

type Props = {
  cardListComponent: React.ReactNode
  card: any
  accept: Function
  ignore: Function
  getUserForID: Function
  connected?: boolean
  ignored?: boolean
  ignoring?: boolean
  isMobile?: boolean
}

class ConnectionInvite extends React.PureComponent<any> {
  render() {
    const { card, cardListComponent, isMobile, ignored, ignoring, t } =
      this.props
    const resource = get(card, 'resource', {})
    const target = resource.target
    const container = cs('listing__item activity-panel', {
      'activity-panel--ignored': ignored || ignoring,
    })
    const header = cs('activity-panel__title', {
      'activity-panel__title--fullwidth': !isMobile,
    })
    const actionMessages = {
      connected: 'updates.connection_invite_accepted',
      accepted: 'updates.connection_invite_accepting',
      accept: 'updates.connection_invite_accept',
    }

    return (
      <div className={container}>
        <div className="activity-panel__header dark-background">
          <h3 className={header}>
            {t('updates:updates.connection_invite_accepted_title', {
              name: target.name,
              first_name: target.name.replace(/^([^ ]+).*$/, '$1'),
            })}
          </h3>
          {!ignoring && !ignored && (
            <button
              className="action-link"
              onClick={() => this.props.ignore(card)}
            >
              <XIcon width={16} />
            </button>
          )}
        </div>

        <div className="activity-panel__body">
          <div className="activity-panel__body-inner">
            <ProfileBasic
              user={target}
              size="profile-thumb"
              className="activity-panel__profile"
              show_institution
            />
          </div>
          <div className="activity-panel__body-section">
            <div className="card__links">
              {renderCardLinks(resource, target, t)}
            </div>
          </div>
          {renderCardLists(
            cardListComponent,
            resource,
            this.props.getUserForID
          )}
        </div>

        {renderActivityPanel(resource, isMobile, this.props, actionMessages, t)}
      </div>
    )
  }
}

/**
 * Render Card Links
 * @param resource
 * @param target
 * @returns {Array}
 */
function renderCardLinks(resource, target, t) {
  const links = []

  if (resource.groups_count + resource.institutions_count) {
    links.push(
      <TextLink
        key="groups"
        className="action-link"
        to={link_for_resource(target.type, target.id, 'groups')}
      >
        {t('member:member.group_institution_count_lower', {
          count: resource.groups_count + resource.institutions_count,
        })}
      </TextLink>
    )
    links.push(<span key="groups-sep"> | </span>)
  }
  if (resource.colleagues_count) {
    links.push(
      <TextLink
        key="colleagues"
        className="action-link"
        to={link_for_resource(target.type, target.id, 'connections')}
      >
        {t('member:member.connection_count', {
          count: resource.colleagues_count,
        })}
      </TextLink>
    )
    links.push(<span key="colleagues-sep"> | </span>)
  }
  if (resource.cases_count) {
    links.push(
      <TextLink
        key="cases"
        className="action-link"
        to={link_for_resource(target.type, target.id, 'cases')}
      >
        {resource.cases_count_label}
      </TextLink>
    )
    links.push(<span key="cases-sep"> | </span>)
  }

  links.pop()

  return links
}

/**
 * Render Card List
 * @param CardListComponent
 * @param resource
 * @param getUserForID
 * @returns {XML}
 */
function renderCardLists(CardListComponent, resource, getUserForID) {
  const ncolleagues = resource.colleagues ? resource.colleagues.length : 0
  const ngroups =
    (resource.groups ? resource.groups.length : 0) +
    (resource.institutions ? resource.institutions.length : 0)
  const ncases = resource.cases ? resource.cases.length : 0

  if ((ncolleagues > 1 || ngroups + ncases === 0) && resource.colleagues) {
    return (
      <CardListComponent
        cards={resource.colleagues
          .map(g => {
            return { target: getUserForID(g) }
          })
          .filter(card => !!card.target)}
        layout={{ _: 1 }}
        cardStyle="row"
      />
    )
  }
  if (ncases > 0) {
    return (
      <CardListComponent
        cards={resource.cases.entries}
        layout={{ _: 1 }}
        cardStyle="row"
      />
    )
  }
  if (ngroups > 0) {
    return (
      <CardListComponent
        cards={concat(resource.groups || [], resource.institutions || []).slice(
          0,
          2
        )}
        layout={{ _: 1 }}
        cardStyle="row"
      />
    )
  }
}

/**
 * Render Activity Panel at footer
 * @param resource
 * @param isMobile
 * @param props
 * @param actionMessages
 * @returns {*}
 */
function renderActivityPanel(resource, isMobile, props, actionMessages, t) {
  const { colleagues, institutions, target, colleagues_count, groups, cases } =
    resource

  const connectionHasColleague =
    colleagues && colleagues.length < colleagues_count
  const connectionHasGroupOrInstitution =
    (groups && groups.length > 1) || (institutions && institutions.length > 1)
  const connectionHasCase = cases && cases.entries.length > 1

  if (connectionHasColleague) {
    return (
      <div className="activity-panel__footer">
        <TextLink
          className="action-link"
          to={link_for_resource(target.type, target.id, 'connections')}
        >
          {t('common:ui.view_all')}
        </TextLink>
        {isMobile && (
          <ActionButton {...props} messages={actionMessages} t={t} />
        )}
      </div>
    )
  }
  if (connectionHasGroupOrInstitution) {
    return (
      <div className="activity-panel__footer">
        <TextLink
          className="action-link"
          to={link_for_resource(target.type, target.id, 'groups')}
        >
          {t('common:ui.view_all')}
        </TextLink>
        {isMobile && (
          <ActionButton {...props} messages={actionMessages} t={t} />
        )}
      </div>
    )
  }
  if (connectionHasCase) {
    return (
      <div className="activity-panel__footer">
        <TextLink
          className="action-link"
          to={link_for_resource(
            resource.target.type,
            resource.target.id,
            'cases'
          )}
        >
          {t('common:ui.view_all')}
        </TextLink>
        {isMobile && (
          <ActionButton {...props} messages={actionMessages} t={t} />
        )}
      </div>
    )
  }

  return null
}

const mapStateToProps = (state: any, { card }: Props): any => {
  const target_id = get(card, 'resource.target.id')

  return {
    connected: target_id
      ? entityState.userHasConnection(state, target_id)
      : false,
    ignored: card.visible === false,
    ignoring: apiState.isRequestActive(state, hideUpdate(card)),
    isMobile: selectIsMobile(state),
  }
}
const mapDispatchToProps = (dispatch): any => ({
  ignore(card) {
    dispatch(hideUpdate(card))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUserLookup(withTranslation()(ConnectionInvite)))
