import React from 'react'
import cs from 'classnames'

// ///////////////////////////////////////////////////////////////////// COMPONENTS
import SearchIcon from '../../icons/search'
import TextButton from '../../buttons/text'

interface CategoryRowProps {
  card: any
  className?: string
}

const CategoryRow = ({ card, className }: CategoryRowProps) => {
  const classes = cs(className, `card-row card-row--${card.target.type}`)
  // const className = (props.className ? props.className + ' ' : '') + '' + card.target.type;  todo: delete after

  return (
    <div className={classes}>
      <div className="category-link">
        <SearchIcon className="category-link__icon" />
        <TextButton className="category-link__a">
          {card.target.label}
        </TextButton>
      </div>
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default CategoryRow
