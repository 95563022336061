import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

import FormattedDate from '../../../components/CMS/FormattedDate'
import {
  UserLookupProps,
  withUserLookup,
} from '../../../modules/entities/components/user-lookup'
import { consentCutoffHoursRemaining } from '../../../modules/entities/consent'
import { CardType } from '../../../types/caseTypes'
import { AuthenticatedUser, BasicUser } from '../../../types/userTypes'
import PollIcon from '../../../ui/icons/Poll'
import Button from '../../buttons/button'
import SmartFollowButton from '../../buttons/smart-follow'
import SmartInviteColleaguesButton from '../../buttons/smart-invite-colleagues'
import TextButton from '../../buttons/text'
import FaceList from '../../face-list'
import ResourceLink from '../../resource-link'
import { getSizeStringWithPixelDensity } from '../../../ui/Img/image'

// ////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////

type FeedEntryType = {
  card?: CardType
  className?: string
  resourceContext?: any
  onEditCase?: Function
  onPublishCase?: Function
  onEditCaseSettings?: Function
  onEditConsent?: Function
  isMobile: boolean
  onGetApp?: Function
  currentUser?: AuthenticatedUser
}

const FeedEntry = ({
  card,
  className,
  isMobile,
  onGetApp,
  onEditCase,
  onPublishCase,
  onEditConsent,
  resourceContext,
  currentUser,

  getUserForID,
}: FeedEntryType & UserLookupProps): React.ReactElement => {
  const containerClasses: string = cs(
    className,
    'fs-unmask',
    'card',
    `card--${card.target.type}`,
    'card--my-case'
  )
  const user = getUserForID(card.target.created_by)
  const cardVisibility = cs('card__visibility', {
    'card__visibility--consent-needed': card.target.consent_cutoff,
  })
  const inlineStyle = {
    backgroundImage: card.target.image_url
      ? `url(${card.target.image_url.replace(
          '{image_size}',
          getSizeStringWithPixelDensity('feed-image')
        )})`
      : 'none',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  }

  const { t } = useTranslation('case')

  if (!user) {
    console.log('Missing creator', card)
    return null
  }

  const onClick = (): any =>
    isMobile ? onGetApp('edit_case') : onEditCase(card.target)
  const onPublish = (): any =>
    isMobile ? onGetApp('edit_case') : onPublishCase(card.target)
  const onClickConsent = (): any => onEditConsent(card.target)

  return (
    <div className={containerClasses}>
      <div className={cardVisibility}>
        <div className="card__visibility-label">
          {card.target.consent_cutoff ? (
            card.target.created_as === 'poll' ? (
              t('visibility.poll_draft_needs_consent')
            ) : (
              t('visibility.draft_needs_consent')
            )
          ) : card.target.status === 'draft' ? (
            card.target.created_as === 'poll' ? (
              t('visibility.poll_draft')
            ) : (
              t('visibility.draft')
            )
          ) : card.target.status === 'scheduled' ? (
            <span>
              {t('case:visibility.scheduled')}
              <FormattedDate
                date={card.target.schedule_publish_at}
                format="numeric"
                withTime
              />
            </span>
          ) : (
            t(`visibility.shared_${card.target.visibility}`)
          )}
        </div>
        <div>
          <TextButton className="action-link" onClick={onClick}>
            {card.target.created_as === 'poll'
              ? t('poll.edit_details_button_label')
              : t('case.edit_details_button_label')}
          </TextButton>
        </div>
      </div>
      <div className="card__resource">
        <h2 className="card__title">
          <span
            className="card__title-background fs-exclude"
            style={inlineStyle}
          ></span>
          <ResourceLink
            className="card__text-link"
            to={card.target}
            resourceContext={resourceContext}
          >
            <span className="card__text--link-text fs-unmask">
              <span className="fs-unmask">
                {card.target.title || card.target.placeholder_title}
              </span>
            </span>
          </ResourceLink>
        </h2>
        {card.new_comment_count ? (
          <span className="badge badge--wide badge--new">
            {t('card.new_comment_count_label', {
              count: card.new_comment_count,
            })}
          </span>
        ) : card.comment_count ? (
          <span className="badge badge--wide badge--seen">
            {t('card.comment_count_label', {
              count: card.comment_count as number,
            })}
          </span>
        ) : null}
        {card.target.poll ? <PollIcon className="card__type-icon" /> : null}
      </div>
      <div className="card__footer">
        {renderButton(
          card,
          currentUser,
          user,
          onClickConsent,
          onClick,
          onPublish,
          getUserForID
        )}
      </div>
    </div>
  )
}

function renderButton(
  card: CardType,
  currentUser: AuthenticatedUser,
  user: BasicUser,
  onClickConsent: Function,
  onClick: Function,
  onPublish: Function,
  getUserForID: Function
): any {
  const { t } = useTranslation('case')

  if (card.target.consent_cutoff) {
    return (
      <button
        className="card__consent-countdown"
        type="button"
        onClick={() => onClickConsent()}
      >
        {t('case.consent_needed_soon', {
          hours: consentCutoffHoursRemaining(card.target.consent_cutoff),
        })}
      </button>
    )
  }
  if (card.target.status === 'draft') {
    return card.target.publishable ? (
      <Button wide onClick={() => onPublish()}>
        {t('case.save_and_publish_button_label')}
      </Button>
    ) : (
      <Button wide onClick={() => onClick()}>
        {t('case.complete_case_button_label')}
      </Button>
    )
  }
  if (user.id && currentUser) {
    const ButtonComponent =
      user.id === currentUser.id &&
      card.target.can_share &&
      card.target.visibility !== 'public'
        ? SmartInviteColleaguesButton
        : SmartFollowButton

    return (
      <div className="card__action">
        <ButtonComponent
          currentUser={currentUser}
          resource={card.target}
          wide
        />
      </div>
    )
  }

  if (card.target.visibility === 'private') {
    const uri = `/cases/${card.id}/edit`
    const uriEncoded = encodeURI(uri)

    return (
      <a href={uriEncoded}>
        <Button wide>{t('case.change_visibility_button_label')}</Button>
      </a>
    )
  }

  return (
    <div className="card__followers">
      <FaceList
        users={card.interested_users
          .map((id: any): any => getUserForID(id))
          .filter((user: any): any => !!user)}
        count={4}
        total={
          card.follower_count ||
          (card.interested_users && card.interested_users.length)
        }
      />
    </div>
  )
}

export default withUserLookup(FeedEntry)
