import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { withUserLookup } from '../../../modules/entities/components/user-lookup'
import { AuthorizationResourceLink } from '../../../ui/AuthorizationResourceLink'
import TextFormatter from '../../formatters/text'
import Image from '../../Img/image'
import * as stats from '../entry-stats'

interface Props {
  card: any
  getUserForID: () => void
  className?: string
}

const GroupRow = ({ card, className }: Props) => {
  const { t } = useTranslation('group')
  const classes = cs(className, `card-row card-row--${card.target.type}`)

  return (
    <div className={classes}>
      <div className="media">
        {(() => {
          if (card.target.image_url) {
            return (
              <AuthorizationResourceLink
                to={card.target}
                className="media__img"
              >
                <Image
                  url={card.target.image_url}
                  size="item"
                  className="card-row__image"
                />
              </AuthorizationResourceLink>
            )
          }
        })()}
        <div className="media__body">
          <AuthorizationResourceLink
            className="card-row__title media__title card__text-link"
            to={card.target}
          >
            {card.target.title}
          </AuthorizationResourceLink>
          <TextFormatter
            className="card-row__description media__subtitle"
            text={card.target.description || ''}
            maxSentences={1}
            maxWords={20}
          />
          <p className="card-row__stats">{stats.getStatsForGroup(card, t)}</p>
        </div>
      </div>
    </div>
  )
}

export default withUserLookup(GroupRow)
