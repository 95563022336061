import { path } from 'ramda'

export const isDABCaseEntry = entry =>
  (path(['target', 'visible_to'], entry) || []).filter(
    x => x['kind'] === 'advisory_board'
  ).length > 0

export const isAdminOfInvitedGroup = entry =>
  (path(['target', 'visible_to'], entry) || []).filter(x => x['is_admin'])
    .length > 0
