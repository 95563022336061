import { useTranslation } from 'next-i18next'
import React from 'react'
import { userHasConnection } from '../../../modules/entities/selectors'

import { useSelector } from '../../../react-redux'
import SmartConnectButton from '../../buttons/smart-connect'
import SmartJoinGroupButton from '../../buttons/smart-join-group'
import ResourceLink from '../../resource-link'
import MediaBox from '../MediaBox'
import { FeedCard } from './flowTypes'

type Props = {
  card: FeedCard
}

const OtherJoinedGroupContainer = ({ card, children }) => {
  const isConnectedToUser = !!useSelector(state =>
    userHasConnection(state, card.actor.id)
  )
  const { resource, actor } = card
  const target = resource.target
  const renderButton = () => {
    if (isConnectedToUser) {
      return (
        <SmartJoinGroupButton
          className="btn--iconic"
          resource={resource}
          group={target}
        />
      )
    } else {
      return <SmartConnectButton className="btn--iconic" user={actor} />
    }
  }

  return (
    <MediaBox
      actor={actor}
      sideKick={renderButton()}
      customeClass="activity--member"
    >
      <ResourceLink className="activity__title" to={actor}>
        {actor.name}
      </ResourceLink>

      {children}
    </MediaBox>
  )
}

export const OtherJoinedGroup = (props: Props) => {
  const target = props.card.resource.target

  const { t } = useTranslation('updates')

  return (
    <OtherJoinedGroupContainer {...props}>
      <div>
        <div className="activity__reason-prefix">
          {t('updates.other_joined_group')}
        </div>
        <div className="activity__reason">
          <ResourceLink to={target}>{target.title}</ResourceLink>
        </div>
      </div>
    </OtherJoinedGroupContainer>
  )
}

export const OtherJoinedThisGroup = (props: Props) => {
  const actor = props.card.actor
  const target = props.card.resource.target

  const { t } = useTranslation('updates')

  return (
    <OtherJoinedGroupContainer {...props}>
      <div>
        <div className="profile-basic__job-title">{actor.job_title}</div>
        <div className="activity__reason">
          {t(
            target?.type === 'institution'
              ? 'updates.other_joined_this_institution'
              : 'updates.other_joined_this_group'
          )}
        </div>
      </div>
    </OtherJoinedGroupContainer>
  )
}
