import { useTranslation } from 'next-i18next'
import React from 'react'

import ResourceLink from '../../resource-link'
import UserIcon from '../../icons/user'
import MediaBox from '../MediaBox'

type Props = {
  card: any
}

const ConnectionAccepted = ({ card }: Props): any => {
  const { t } = useTranslation()

  return (
    <MediaBox actor={card.actor} customeClass="activity--member">
      <ResourceLink className="activity__title" to={card.actor}>
        {card.actor.name}
      </ResourceLink>
      <div className="profile-basic__job-title">{card.actor.job_title}</div>
      <div className="activity__reason">
        {t('updates:updates.connection_accepted')}
      </div>
      <button className="btn btn--iconic">
        <ResourceLink to={card.actor}>
          <span>{t('member:member.view_profile')}</span>
          <UserIcon />
        </ResourceLink>
      </button>
    </MediaBox>
  )
}

export default ConnectionAccepted
