import { TFunction, withTranslation } from 'next-i18next'
import React from 'react'

import Spinner from '../../icons/spinner'
import Tick from '../../icons/tick'

type Props = {
  connected?: boolean
  ignored?: boolean
  accepting?: boolean
  ignoring?: boolean
  accept: Function
  card: any
  messages: {
    connected: string
    accepted: string
    accept: string
  }
  withTick?: boolean
  t: TFunction
}

class ActionButton extends React.Component<Props> {
  static defaultProps = {
    withTick: false,
  }

  render() {
    const {
      connected,
      accepting,
      accept,
      ignored,
      ignoring,
      card,
      withTick,
      messages,
      t,
    } = this.props

    if (connected) {
      return (
        <button className="btn btn--fill btn--dark btn--wide">
          <>{messages.connected.length > 0 && t(messages.connected)}</>
        </button>
      )
    } else if (accepting) {
      return (
        <button className="btn btn--disabled btn--wide">
          <>
            {messages.accepted.length > 0 && t(messages.accepted)}
            <Spinner width={15} />
          </>
        </button>
      )
    } else if (
      !ignored &&
      !ignoring &&
      messages.accept &&
      messages.accept.length > 0
    ) {
      return (
        <button
          className="btn btn--wide"
          onClick={() => accept(card.resource, card.actor)}
        >
          <>
            {t(messages.accept)}
            {withTick && <Tick />}
          </>
        </button>
      )
    }

    return null
  }
}

export default ActionButton
