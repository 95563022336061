import { connect } from 'react-redux'

import { selectUserEntities } from '../modules/entities/selectors'

import FaceList from './face-list'

const mapStateToProps = state => {
  return {
    user_map: selectUserEntities(state),
  }
}

export default connect(mapStateToProps)(FaceList)
