import { useEffect } from 'react'
import { useSelector, useDispatch } from '../../react-redux'
import { selectGroup } from './selectors'
import loadGroup from './actions/group_load'

export const useGroup = (id: string) => {
  const dispatch = useDispatch()
  const group = useSelector(state => selectGroup(state, 'group', id))
  useEffect(() => {
    if (!group) {
      dispatch(loadGroup('group', id))
    }
  }, [id, group, dispatch])
  return group
}
