import cs from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'

import Stethoscope from '../icons/stethoscope'
import { TextLink } from '../link'

const CreateCase = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  return (
    <TextLink to="/cases/create">
      <span
        className={cs(className, 'btn--iconic btn--connect btn stethoscope')}
      >
        <span className="btn--connect__label">
          {t('common:menu.create_case')}
        </span>
        <Stethoscope />
      </span>
    </TextLink>
  )
}

export default CreateCase
