import { request } from '../../api/actions'
import * as schemas from '../../entities/schemas'

export const LOAD_REQUEST = 'entities/group_load.REQUEST'
export const LOAD_SUCCESS = 'entities/group_load.SUCCESS'
export const LOAD_FAILURE = 'entities/group_load.FAILURE'

export default function loadGroup(type, id) {
  const groupEntrySchema = schemas.getGroupEntrySchema(type)
  return request({
    id: `${type}/${id}`,
    url:
      '/api/profile/feeds/' +
      encodeURIComponent(type) +
      '/' +
      encodeURIComponent(id) +
      '?connections_per_page=1000&include=connections%2Cfounders%2Cadmins%2C-updates',
    start: { type: LOAD_REQUEST, resource_type: type, resource_id: id },
    success: {
      type: LOAD_SUCCESS,
      meta: {
        patch: { response: { target: { _fullyLoaded: { $set: true } } } },
        schema: { response: groupEntrySchema },
      },
    },
    failure: LOAD_FAILURE,
  })
}

export function loadPublicGroup(type, id) {
  const groupEntrySchema = schemas.getGroupEntrySchema(type)
  return request({
    id: `${type}/${id}/public`,
    url: `/api/profile/feeds/${encodeURIComponent(
      type
    )}/public/${encodeURIComponent(id)}`,
    start: { type: LOAD_REQUEST, resource_type: type, resource_id: id },
    success: {
      type: LOAD_SUCCESS,
      meta: {
        patch: { response: { target: { _fullyLoaded: { $set: true } } } },
        schema: { response: groupEntrySchema },
      },
    },
    failure: LOAD_FAILURE,
  })
}
